export const REACT_BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_BASE_DOMAIN =
  process.env.REACT_APP_BASE_URL === "https://api.humanas.io" ? "app.humanas.io" : "apptest.humanas.io";

export const ENDPOINTS = {
  loginCompany: {
    path: "/login/company",
    method: "POST",
    isAuthenticated: false,
  },
  loginWithToken: {
    path: "/login/refreshsession",
    method: "GET",
    isAuthenticated: true,
  },
  loginCheck: {
    path: "/login/check",
    method: "POST",
    isAuthenticated: false,
  },
  loginState: {
    path: "/login/state",
    method: "POST",
    isAuthenticated: false,
  },
  createCompany: {
    path: "/ccr",
    method: "POST",
    isAuthenticated: false,
  },
  confirmAccount: {
    path: "/login/confirmation",
    method: "POST",
    isAuthenticated: false,
  },
  twoFactorAuth: {
    path: "/login/twofactor",
    method: "POST",
    isAuthenticated: false,
  },
  resetPasswordRequest: {
    path: "/user/resetpassword",
    method: "GET",
    isAuthenticated: false,
  },
  resetPasswordSubmit: {
    path: "/user/resetpassword",
    method: "POST",
    isAuthenticated: false,
  },
  getCompanyInfo: {
    path: "/company",
    method: "GET",
    isAuthenticated: true,
  },
  getDashboardContent: {
    path: "/dashboard",
    method: "GET",
    isAuthenticated: true,
  },
  getOverviewContent: {
    path: "/overview",
    method: "GET",
    isAuthenticated: true,
  },
  updateApplicationForm: {
    path: "/overview/identity",
    method: "PUT",
    isAuthenticated: true,
  },
  getCoreCV: {
    path: "/overview/identity",
    method: "GET",
    isAuthenticated: true,
  },
  coreCVPhoneConfirmation: {
    path: "/overview/confirmation/phone",
    method: "POST",
    isAuthenticated: true,
  },
  myNetworkList: {
    path: "/company/mynetwork",
    method: "POST",
    isAuthenticated: true,
  },
  companyInvite: {
    path: "/company/invite",
    method: "POST",
    isAuthenticated: true,
  },
  resendCompanyInvite: {
    path: "/company/invite/resend",
    method: "POST",
    isAuthenticated: true,
  },
  metaCvActions: {
    path: "/company/mynetwork/action",
    method: "POST",
    isAuthenticated: true,
  },
  sendSupportTicket: {
    path: "/dashboard/support",
    method: "POST",
    isAuthenticated: true,
  },
  getCommunityCandidate: {
    path: "/overview/community",
    method: "GET",
    isAuthenticated: true,
  },
  getContent: {
    path: "/content",
    method: "GET",
    isAuthenticated: false,
  },
  getStrapiContent: {
    path: "/content/strapi",
    method: "GET",
    isAuthenticated: false,
  },
  deleteInvite: {
    path: "/company/invite",
    method: "DELETE",
    isAuthenticated: true,
  },
  groupActions: {
    path: "/company/group",
    method: "POST",
    isAuthenticated: true,
  },
  getGroupList: {
    path: "/company/group",
    method: "GET",
    isAuthenticated: true,
  },
  publicInvite: {
    path: "/campaign",
    method: "GET",
    isAuthenticated: false,
  },
  reportActions: {
    path: "/company/report",
    method: "POST",
    isAuthenticated: true,
  },
  getAllReports: {
    path: "/company/report",
    method: "GET",
    isAuthenticated: true,
  },
  getSettings: {
    path: "/company/settings",
    method: "GET",
    isAuthenticated: true,
  },
  setSettings: {
    path: "/company/settings",
    method: "POST",
    isAuthenticated: true,
  },
  publicMetacvView: {
    path: "/company/view",
    method: "GET",
    isAuthenticated: false,
  },
  getAid: {
    path: "/company/aid",
    method: "GET",
    isAuthenticated: true,
  },
  downloadMetaCv: {
    path: "/downloadmetacv",
    method: "GET",
    isAuthenticated: true,
  },
  getCityOptions: {
    path: "/content/city",
    method: "GET",
    isAuthenticated: true,
  },
  getSectorOptions: {
    path: "/content/sector",
    method: "GET",
    isAuthenticated: true,
  },
  getCountryOptions: {
    path: "/content/country",
    method: "GET",
    isAuthenticated: true,
  },
  getStateOptions: {
    path: "/content/state",
    method: "GET",
    isAuthenticated: true,
  },
  setTwoFA: {
    path: "/company/settings/2fa",
    method: "POST",
    isAuthenticated: true,
  },
  uploadInviteList: {
    path: "/company/invite/file",
    method: "POST",
    isAuthenticated: true,
  },
  getCnaEntranceSummmary: {
    path: "/company/cna/entrance",
    method: "GET",
    isAuthenticated: true,
  },
  getCnaDashboard: {
    path: "/company/cna/dashboard",
    method: "GET",
    isAuthenticated: true,
  },
  postCnaEntranceSummmary: {
    path: "/company/cna/entrance",
    method: "POST",
    isAuthenticated: true,
  },
  getJobPosition: {
    path: "/company/cna/jobposition",
    method: "GET",
    isAuthenticated: true,
  },
  postJobPosition: {
    path: "/company/cna/jobposition",
    method: "POST",
    isAuthenticated: true,
  },
  deleteJobPosition: {
    path: "/company/cna/jobposition",
    method: "Delete",
    isAuthenticated: true,
  },
  getJobPositionReport: {
    path: "/company/cna/match",
    method: "GET",
    isAuthenticated: true,
  },
  getCnaConnections: {
    path: "/company/cna/connections",
    method: "GET",
    isAuthenticated: true,
  },
};
