import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../store/useStore";
import { BackCircleIcon } from "../components/OldDesign/Icons";
import Modal from "../components/OldDesign/Modal";
import PlayLazyGuruPopup from "../components/NewDesign/PlayLazyGuruPopup";
import { encode, decode, Base64 } from "js-base64";
import PlayLazyGuru from "../components/NewDesign/PlayLazyGuru";
import { toast } from "react-toastify";

const AuthLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStore((state) => state.user);
  const authenticated = useStore((state) => state.authenticated);
  const loginState = useStore((state) => state.loginState);
  const persistSession = useStore((state) => state.persistSession);
  const loginAction = useStore((state) => state.loginAction);
  const setLoginAction = useStore((state) => state.setLoginAction);
  const setLoginSuccess = useStore((state) => state.setLoginSuccess);
  const [showModal, setShowModal] = useState(null);

  const [searchParams] = useSearchParams();
  const param_user = searchParams.get("user"); 
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const loginApple = searchParams.get("loginApple");
  // console.log(code, state);
  const storedToken = localStorage.getItem("token");
  const showBackButton = location.pathname !== "/login" && location.pathname !== "/pricing";

  const doNotCheckAuthForPasswordSetPage = location.pathname === "/register/password";

  useEffect(() => {
    if (loginAction.params.location === "play-lazy-guru") {
      if (localStorage.getItem("campaign") !== null) {
      } else {
        setShowModal("play-lazy-guru");
      }
    }
  }, [loginAction]);

  useEffect(() => {
    if (!doNotCheckAuthForPasswordSetPage) {
      if (!authenticated) {
        if (code && state) {
          loginState({ code: code, state: state, user:param_user??null  });

          //if invite via google web login
          if (state && state.includes("google-web-login-")) {
            let campaign = state.substring(state.indexOf("google-web-login-") + "google-web-login-".length);
            localStorage.setItem("campaign", campaign);
            // setShowModal("download-lazy-guru");
          }
          else if(state && state.includes("apple-web-login-")){
            let campaign = state.substring(state.indexOf("apple-web-login-") + "apple-web-login-".length);
            localStorage.setItem("campaign", campaign);
            // setShowModal("download-lazy-guru");
          }
        } else if (storedToken) {
          persistSession();
        }
        //  else if (loginApple) {
        //   //if invite via apple web login
        //   let log = Base64.decode(loginApple);
        //   let json = JSON.parse(log);
        //   setLoginSuccess(json);
        //   localStorage.setItem("campaign", json.campaign);
        //   if (json.status < 0) {
        //     toast.error("hata");
        //   }
        //   // if (json.campaign) {
        //   //   setShowModal("download-lazy-guru");
        //   // }
        // }
      }
    }
  }, [
    doNotCheckAuthForPasswordSetPage,
    loginState,
    authenticated,
    persistSession,
    navigate,
    setLoginSuccess,
    code,
    state,
    loginApple,
    storedToken,
  ]);

  useEffect(() => {
    if (!doNotCheckAuthForPasswordSetPage) {
      if (authenticated) {
        if (user.userType === "candidate") {
          navigate("/overview");
        } else {
          navigate("/dashboard");
        }
      }
    }
  }, [doNotCheckAuthForPasswordSetPage, navigate, authenticated, user]);

  if ((!authenticated && storedToken) || (code && state)) {
    return null;
  }
  return (
    <>
      <div
        className={`relative h-screen w-full overflow-hidden bg-neutral-200 bg-[url("/src/assets/sideDesign.png"),_url("/src/assets/sideDesignreverse.png")] bg-[position:top_right,bottom_left] bg-no-repeat font-inter text-[14px] lg:flex lg:min-h-[680px] lg:items-center lg:text-[17px]`}
      >
        <div className="z-10 lg:mx-auto lg:w-full lg:max-w-[1184px]">
          {showBackButton ? (
            <div className="relative">
              <div
                className="absolute left-[4%] top-16 z-10 cursor-pointer lg:-top-[174px] lg:left-0"
                onClick={() => navigate(-1)}
              >
                <BackCircleIcon color="white" className="h-6 w-6 lg:hidden" />
                <BackCircleIcon color="#4089BD" className="h-9 w-9 max-lg:hidden" />
              </div>
            </div>
          ) : null}
          <div className="flex h-[33vh] bg-[linear-gradient(258deg,#0D568A_0%,#2878BE_36.98%,#0DC9B1_100%)] lg:h-[335px]">
            <Outlet />
          </div>
        </div>
      </div>
      {showModal === "play-lazy-guru" && (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <PlayLazyGuruPopup loginAction={loginAction} />
        </Modal>
      )}
      {showModal === "download-lazy-guru" && (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <PlayLazyGuru />
        </Modal>
      )}
    </>
  );
};

export default AuthLayout;
