import React, { useEffect, useState } from "react";
import ExpBar from "../../../../components/NewDesign/StatusBar/ExpBar";
import {
  CheckFilled,
  CheckOutlined,
  EditOutlined,
  PeopleFilled,
  PlusCircularOutlined,
  PlusOutlined,
  RubbishOutlined,
  TickOutlined,
} from "../../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../components/OldDesign/Loading";
import Button from "../../../../components/NewDesign/Button/Button";
import { useStore } from "../../../../store/useStore";
import Pagination from "../../../../components/OldDesign/Pagination";
import emptyusers_aid from "../../../../assets/emptyusers_aid.png";
import ConfirmationPopup from "../../../../components/NewDesign/Popups/ConfirmationPopup";
import { deleteJobPosition, postJobPosition } from "../../../../api/endpoints";
import { InputNumber } from "../../../../components/NewDesign/Forms/Input";
import { toast } from "react-toastify";

const JobPositionRow = ({ position, setReload }) => {
  const [posName, setPosName] = useState(position.name);
  const [seatCount, setSeatCount] = useState(position.seat_count);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const openAnalysisModal = (id) => {
    navigate(`/jobposition/${id}/home`, {
      state: { previousLocation: location },
    });
  };
  const [editMode, setEditMode] = useState(false);

  const handleEditButton = () => {
    if (!editMode) {
      setEditMode(true);
    } else {
      if (posName === position.name && seatCount === position.seat_count) {
        setEditMode(false);
      } else {
        postJobPosition(
          null,
          { name: posName, seat_count: seatCount },
          `/${position.id}`,
          (res) => {
            setReload((prev) => prev + 1);
            toast.success("İş Pozisyonu güncellendi");
            setEditMode(false);
          },
          (err) => {
            console.log(err);
            toast.error("İş Pozisyonu güncellenemedi");
            setEditMode(false);
          },
          (bool) => {
            setLoading(bool);
          },
        );
      }
    }
  };

  const handleDeleteButton = () => {
    setShowDeleteModal(true);
  };

  const handlePosNameChange = (e) => {
    const _posName = e.target.value;
    setPosName(_posName);
  };
  return (
    <>
      <div className="flex flex-wrap items-center justify-between rounded-lg border border-neutral-300 bg-neutral-100 px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] max-lg:gap-3">
        <input
          title={posName}
          className={`${
            editMode && "border border-neutral-300 bg-neutral-200 p-1"
          } basis-[25%] overflow-ellipsis whitespace-nowrap rounded-md font-medium leading-tight text-primary-800 transition-all max-lg:basis-1/3`}
          value={posName}
          onChange={handlePosNameChange}
          readOnly={!editMode}
        />
        <div className="flex basis-[20%] items-center gap-0.5 max-lg:basis-1/3 max-lg:justify-end">
          {editMode ? (
            <>
              <InputNumber className="my-0 h-7" initialValue={seatCount} min={0} max={9999} onChange={setSeatCount} />
            </>
          ) : (
            <>
              <PeopleFilled className="h-3 w-3 text-primary-900" />
              <p className="text-[13px] font-medium leading-tight text-primary-900">
                {position.seat_metacv_count}/{position.seat_count}
              </p>
            </>
          )}
        </div>
        <div className="flex basis-[15%] flex-col max-lg:basis-full">
          <span className="text-[13px] font-bold leading-tight text-chart-purple2">
            {position.progress_percent_total} %
          </span>
          <ExpBar className="h-2" percent={position.progress_percent_total} />
        </div>
        <div className="flex basis-[22%] gap-1">
          {position.state === "completed" ? (
            <Button
              onClick={() => navigate(`/cna/my-positions/${position.id}`)}
              className="flex-1 border-secondary_applicant-700 bg-secondary_applicant-100 px-6 py-2 text-secondary_applicant-700"
            >
              Görüntüle
            </Button>
          ) : (
            <Button
              onClick={() => openAnalysisModal(position.id)}
              className="flex-1 bg-primary-50 px-6 py-2 text-primary-600"
            >
              {position.progress_percent_total > 1 ? "Devam Et" : "Başla"}
            </Button>
          )}
          <Button
            onClick={handleEditButton}
            loading={loading}
            className={"border-neutral-300 bg-neutral-100 p-2"}
            icon={
              <div className="relative">
                <TickOutlined className={`h-5 w-5 text-success-400 ${!editMode && "scale-0"} transition-all`} />
                <EditOutlined
                  className={`h-5 w-5 text-success-400 ${editMode && "scale-0"} absolute left-0 top-0 transition-all`}
                />
              </div>
            }
          />
          <Button
            onClick={handleDeleteButton}
            className={"border-neutral-300 bg-neutral-100 p-2"}
            icon={<RubbishOutlined className={"h-5 w-5 text-card_candidate-400"} />}
          />
        </div>
      </div>
      <ConfirmationPopup
        message={`"${position.name}" ideal çalışan profilini silmek istediğinize emin misiniz? Bu geri alınamaz!`}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        id={position.id}
        confirmModal={() =>
          deleteJobPosition(
            `/${position.id}`,
            (res) => {
              toast.success("İş Pozisyonu silindi");
              setReload((prev) => prev + 1);
            },
            (err) => {
              toast.error("İş Pozisyonu silinemedi");
            },
          )
        }
        confirmationTopic={"Sil"}
      />
    </>
  );
};

const MyJobPositions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getJobPositionList = useStore((state) => state.getJobPositionList);
  const jobPositions = useStore((state) => state.jobPositions);
  const [reload, setReload] = useState(1);

  // PAGINATION
  const [pageSize, setPageSize] = useState(6); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page

  useEffect(() => {
    const options = {
      page: currentPage,
      rows: pageSize,
      // filter: { core_cv_completed: 1, tournament_completed: 1 }
    };
    getJobPositionList(options);
  }, [getJobPositionList, currentPage, pageSize, reload]);

  if (!jobPositions.isLoaded) {
    return null;
  }
  // console.log(jobPositions);
  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between pb-4">
        {/* <h2 className="text-[20px] font-bold leading-tight text-primary-900 max-lg:text-base">İş Pozisyonlarım</h2> */}
        <Button
          onClick={() => {
            navigate(`/add-position`, {
              state: { previousLocation: location, options: { rows: pageSize, page: currentPage } },
            });
          }}
          className={"px-6 py-2 max-lg:px-6 max-lg:py-2"}
          icon2={<PlusOutlined className={"h-4 w-4"} />}
        >
          Yeni Ekle
        </Button>
        <Button
          // onClick={() => {
          //   navigate(`/add-position`, {
          //     state: { previousLocation: location },
          //   });
          // }}
          className={"hidden px-6 py-2 max-lg:px-6 max-lg:py-2"}
          // icon2={<PlusOutlined className={"h-4 w-4"} />}
        >
          Rapor Talep Et
        </Button>
      </div>
      <div className="mb-4 flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2 max-lg:hidden">
        <div className="basis-[25%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Pozisyon İsmi</p>
        </div>
        <div className="basis-[20%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Kişi Sayısı</p>
        </div>
        <div className="basis-[15%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">İlerleme</p>
        </div>
        <div className="basis-[22%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
        </div>
      </div>
      <div className="-mx-2 -my-1 flex flex-col gap-3 overflow-y-auto px-3 py-1 lg:max-h-[calc(100%-142px)]">
        {!jobPositions.isLoading ? (
          <>
            {jobPositions.data.rows.length ? (
              jobPositions.data.rows.map((position, index) => (
                <JobPositionRow key={index} position={position} setReload={setReload} />
              ))
            ) : (
              <div className="flex flex-1 flex-col items-center justify-center gap-1">
                <img src={emptyusers_aid} alt="empty positions" />
                <p className="text-sm font-semibold text-primary-800">Henüz İdeal Çalışan Profili oluşturmadınız.</p>
                <Button
                  onClick={() => {
                    navigate(`/add-position`, {
                      state: { previousLocation: location, options: { rows: pageSize, page: currentPage } },
                    });
                  }}
                  className={"px-6 py-2"}
                  icon={<PlusCircularOutlined className={"h-4 w-4"} />}
                >
                  Pozisyon Ekle
                </Button>
              </div>
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="mt-auto">
        {jobPositions.data.pagination_info.total_row_count > 0 && (
          <Pagination
            dataLength={jobPositions.data.pagination_info.total_row_count}
            setPageSize={setPageSize}
            currentPage={currentPage}
            totalCount={jobPositions.data.pagination_info.total_row_count}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
};

export default MyJobPositions;
