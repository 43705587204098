import React, { useEffect, useState } from "react";
import { AppleLogo, GoogleLogo, HumanasLogo } from "../../components/OldDesign/Icons";
import { DownloadIllustration, SharePageRightCorner, SyncIllustration } from "../../components/NewDesign/Illustrations";
import { QRCodeSVG } from "qrcode.react";
import { publicInvite } from "../../api/endpoints";
import { useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../../components/NewDesign/Button/Button";
import shareMockups from "../../assets/shareMockup.png";
import downloadMockups from "../../assets/shareDownload.png";
import IsMobile from "../../utils/IsMobile";
import { REACT_BASE_DOMAIN } from "../../api/constants";
import useIsMobile from "../../utils/useIsMobile";

const SharePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useIsMobile();
  const { state } = useLocation();
  const [campaignLog, setCampaignLog] = useState(null);
  const [campaign, setCampaign] = useState({
    campaignData: null,
    isLoaded: false,
  });
  const url = window.location.pathname + window.location.search;

  const id = searchParams.get("id");

  const handleGoogleLogin = () => {
    // localhost:3000
    // window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline&prompt=consent&state=google-web-login-${campaign.campaignData.unique_id}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&client_id=894808935805-4fkhfecnsjd1ronct2nkepkj6muomh2a.apps.googleusercontent.com`;

    // apptest.humanas.io
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline&prompt=consent&state=google-web-login-${campaign.campaignData.unique_id}&redirect_uri=https%3A%2F%2F${REACT_BASE_DOMAIN}%2Flogin&client_id=894808935805-4fkhfecnsjd1ronct2nkepkj6muomh2a.apps.googleusercontent.com`;
  };
  const handleAppleLogin = () => {
    // apptest.humanas.io
    // window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=query&client_id=com.humanas.lazygurusignin&redirect_uri=https%3A%2F%2F${REACT_BASE_DOMAIN}%2Flogin&state=apple-web-login`;
    window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=form_post&scope=email%20name&client_id=com.humanas.lazygurusignin&redirect_uri=${
      process.env.REACT_APP_BASE_URL
    }/login/redirect&state=apple-web-login-${campaign.campaignData.unique_id ?? ""}`;
  };
  useEffect(() => {
    const params = { campaign_name: id };
    publicInvite(params, (res) => {
      setCampaign({ campaignData: res.data, isLoaded: true });
      localStorage.setItem("autoLoginLink", url);
    });

    if (localStorage.getItem("campaign") != "null") {
      setCampaignLog(localStorage.getItem("campaign"));
    }
    return () => {
      localStorage.removeItem("campaign");
    };
  }, [id]);
  if (!campaign.isLoaded) {
    return null;
  }
  return (
    <div className="flex h-[33vh] bg-[linear-gradient(128deg,#0D568A_0%,#2878BE_36.98%,#0DC9B1_100%)] lg:h-[335px]">
      <div className={`container2 p-9 pt-16 max-lg:px-0 max-lg:py-3`}>
        <div className="grid grid-cols-5 pb-4 max-lg:grid-cols-1">
          <div className="col-span-2" style={{ paddingTop: "20px" }}>
            <HumanasLogo className="max-lg:text-neutral-250 text-primary-100 max-lg:mx-auto" />
            <p
              style={{ paddingTop: "30px" }}
              className="mx-auto  pb-3 text-[15px] leading-tight text-neutral-200 max-lg:max-w-[600px] max-lg:text-left max-lg:text-[18px] max-lg:text-neutral-100"
            >
              Merhaba
            </p>
            <p className="mx-auto pb-3 text-[15px] leading-tight text-neutral-200 max-lg:max-w-[600px] max-lg:text-left max-lg:text-[18px] max-lg:text-neutral-100">
              {campaign.campaignData.company_name} şirketinden {campaign.campaignData.inviter_name} size Meta CV’nizi{" "}
              {campaign.campaignData.type == "employ" ? "çalışan olarak " : "aday olarak "}
              görüntülemek için bir istek gönderdi.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-9 py-14 max-lg:gap-3 max-lg:py-4">
          {isMobile ? (
            <>
              {/* DOWNLOAD CARD */}
              {!state?.linkFound && (
                <div className="flex w-full flex-col rounded-xl bg-neutral-100 p-9 max-lg:p-4">
                  <div className="mb-9 flex items-center gap-3 border-b border-neutral-300 pb-9 max-lg:mb-4 max-lg:pb-2">
                    <h6 className="text-[28px] font-semibold leading-tight text-primary-700 max-lg:text-[18px]">
                      Hesap Oluştur
                    </h6>
                  </div>
                  <div className="flex w-full flex-col items-center gap-6">
                    <div className="w-full">
                      <button
                        className="flex w-full items-center justify-center gap-1 rounded-[10px] border border-[#D2D2D7] bg-white py-4 text-[13px] leading-5 text-black lg:text-[16px]"
                        onClick={() => handleAppleLogin()}
                      >
                        <AppleLogo />
                        <p className="text-black-200 pl-1 text-[22px] font-medium leading-tight max-lg:text-[16px] lg:text-center">
                          Apple ile Giriş Yap
                        </p>
                      </button>
                    </div>
                    <div className="w-full">
                      <button
                        className="flex w-full items-center justify-center gap-1 rounded-[10px] border border-[#D2D2D7] bg-white py-4 text-[13px] leading-5 text-black lg:text-[16px]"
                        onClick={() => handleGoogleLogin()}
                      >
                        <GoogleLogo />
                        <p className="text-black-700 pl-1 text-[22px] font-medium leading-tight max-lg:text-[16px] lg:text-center">
                          Google ile Giriş Yap
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-center pt-5">
                    <img src={shareMockups} alt="share" className="max-lg:w-[480px]" />
                  </div>
                  <div className="flex w-full flex-row  justify-between">
                    <p className="col text-secondary-100   text-left text-[22px] leading-tight max-lg:w-[120px] max-lg:text-[13px]">
                      <span className="font-bold">Lazy Guru</span> Oyunlaştırılmış kariyer uygulaması
                    </p>
                    <p className="col text-secondary-100 text-right text-[22px] leading-tight max-lg:w-[120px] max-lg:text-[13px]">
                      <span className="font-bold">Meta CV</span> Potansiyel odaklı yeni nesil CV!
                    </p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-col rounded-xl bg-primary-75 p-9 max-lg:p-4">
                <div className="mb-9  flex items-center gap-3 border-b border-neutral-300 pb-9 max-lg:mb-4 max-lg:pb-2">
                  <h6 className="text-[28px] font-semibold leading-tight text-primary-700 max-lg:text-[18px]">
                    Mobil Davet'e Git
                  </h6>
                </div>
                <div className="flex flex-col items-center gap-6">
                  <div className="flex items-center gap-1 lg:flex-col lg:gap-4">
                    <DownloadIllustration className="w-[175px] shrink-0 max-lg:w-[75px]" />
                    <p className="text-[22px] font-medium leading-tight text-primary-700 max-lg:text-[16px] lg:text-center">
                      QR kodu okutarak mobil davetiyeye yönlenebilirsiniz.
                    </p>
                  </div>
                  <div className="rounded-md bg-neutral-100 p-2 max-lg:hidden">
                    <QrCode value={window.location.href} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const QrCode = ({ value }) => {
  return <QRCodeSVG size={165} value={value} />;
};

export default SharePage;
