import React from "react";
import { usePagination, DOTS } from "../../hooks/usePagination";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Pagination = (props) => {
  const { setPageSize, dataLength, onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  // if (currentPage === 0 || paginationRange.length < 2) {
  //   return null;
  // }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="flex flex-row justify-between max-lg:flex-col max-lg:items-center max-lg:gap-2">
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(e.target.value);
          onPageChange(1);
        }}
        className="rounded-lg text-sm max-lg:order-2"
      >
        <option value={6}>6</option>
        <option value={12}>12</option>
        <option value={24}>24</option>
        <option value={48}>48</option>
        <option value={96}>96</option>
      </select>

      {/* <span className="max-lg:order-1 max-lg:basis-full">
        Showing {pageSize * (currentPage - 1) + 1}-
        {currentPage * pageSize < dataLength ? currentPage * pageSize : dataLength} items out of {totalCount} items
      </span> */}
      <span className="max-lg:order-1 max-lg:basis-full">
        {totalCount} öğeden {pageSize * (currentPage - 1) + 1}-
        {currentPage * pageSize < dataLength ? currentPage * pageSize : dataLength} öğe gösteriliyor
      </span>

      <ul className="flex text-sm max-lg:order-3">
        {/* Left navigation arrow */}
        <button
          className="mx-1 my-auto flex h-8 items-center p-3 text-center disabled:opacity-10"
          onClick={onPrevious}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        {paginationRange.map((pageNumber) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber} className="mx-1 my-auto flex h-8 items-center p-3 text-center">
                &#8230;
              </li>
            );
          }

          // Render our Page Pills
          return (
            <li
              key={pageNumber}
              className={`mx-1 my-auto flex h-8 items-center rounded-md p-3 text-center hover:cursor-pointer hover:bg-gray-100 ${
                pageNumber === currentPage ? "bg-primary-400 text-white hover:bg-primary-500" : "bg-none"
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        {/*  Right Navigation arrow */}
        <button
          className="mx-1 my-auto flex h-8 items-center p-3 text-center disabled:opacity-10"
          onClick={onNext}
          disabled={currentPage === lastPage}
        >
          <FaChevronRight />
        </button>
      </ul>
    </div>
  );
};

export default Pagination;
