import React from "react";
import useIsMobile from "../../utils/useIsMobile";
import { HumanasLogo } from "../OldDesign/Icons";
import { DownloadIllustration } from "./Illustrations";
import Button from "./Button/Button";
import downloadMockups from "../../assets/shareDownload.png";
import { QRCodeSVG } from "qrcode.react";

const PlayLazyGuru = () => {
  const isMobile = useIsMobile();

  return (
    <div className={`container2 flex flex-col gap-4 py-9 max-lg:gap-2`}>
      <div className="col-span-2 rounded-lg bg-primary-700 p-1 pt-4 lg:p-6">
        <HumanasLogo className="max-lg:text-neutral-250 text-primary-100 max-lg:mx-auto" />

        <p
          style={{ paddingTop: "30px" }}
          className="mx-auto pb-3 text-[15px] leading-tight text-neutral-200 max-lg:max-w-[600px] max-lg:text-center max-lg:text-sm max-lg:text-neutral-100"
        >
          Üyeliğin tamamlandı!
        </p>

        <p className="mx-auto pb-1 text-[15px] leading-tight text-neutral-200 max-lg:max-w-[600px] max-lg:text-center max-lg:text-sm max-lg:text-neutral-100">
          Artık Lazy Guru'yu indirmeye hazırsın...
        </p>
      </div>

      <div className="flex flex-col items-center justify-center gap-9 max-lg:gap-3">
        <>
          {/* DOWNLOAD CARD */}
          <div className="flex flex-col rounded-xl bg-primary-75 p-9 max-lg:p-4">
            <div className="mb-9  flex items-center gap-3 border-b border-neutral-300 pb-9 max-lg:mb-4 max-lg:pb-2">
              <h6 className="text-[28px] font-semibold leading-tight text-primary-700 max-lg:text-[18px]">
                Lazy Guru' yu İndir
              </h6>
            </div>
            <div className="flex flex-col items-center gap-6">
              <div className="flex items-center gap-1 lg:flex-col lg:gap-4">
                <DownloadIllustration className="w-[175px] shrink-0 max-lg:w-[75px]" />
                <p className="pl-2 text-[22px] font-medium leading-tight text-primary-700 max-lg:text-[16px]  lg:text-center">
                  {isMobile
                    ? `"İndir!" butonuna tıklayarak mağazadan uygulamayı indirebilirsiniz.`
                    : `QR kodu okutarak mağazadan uygulamayı indirebilirsiniz.`}
                </p>
              </div>
              <div className="rounded-md bg-neutral-100 p-2 max-lg:hidden">
                <QrCode value="humanas.io/LazyGuruStore.php" />
              </div>
              <p className=" font-medium   text-primary-700 max-lg:text-[12px] lg:text-center">
                Uygulamayı açınca eşleştirdiğiniz hesap ile giriş yapmayı unutmayın :)
              </p>
              <Button
                onClick={() => window.open("https://humanas.io/LazyGuruStore.php", "_blank")}
                className="w-full lg:hidden lg:bg-neutral-100 lg:text-primary-600"
              >
                İndir!
              </Button>
            </div>
            <div className="flex items-center justify-center" style={{ paddingTop: "20px" }}>
              <img src={downloadMockups} alt="share" className="max-lg:w-[480px]" />
            </div>
            <div className="w-full-row flex  justify-center pt-5">
              <p className="text-secondary-200   text-center text-[22px] leading-tight  max-lg:text-[14px]">
                Kararını ver, senaryoları tamamla ve Meta CV'ni oluştur!
              </p>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

const QrCode = ({ value }) => {
  return <QRCodeSVG size={165} value={value} />;
};

export default PlayLazyGuru;
