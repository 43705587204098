import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import SectionTitle from "../SectionTitle";

const ListItems = ({ item, edu }) => {
  return (
    <li className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
      <p style={{ wordBreak: "break-word" }} className="text-[14px] font-medium leading-tight text-primary-800">
        &#x2022; {item}
      </p>
      {edu && (
        <div className="flex flex-1 justify-between max-lg:w-full">
          <p className="text-[14px] font-medium leading-tight text-neutral-700">{edu.department}</p>
          <p className="text-[14px] font-medium leading-tight text-neutral-700">
            {edu.start_date.replace("-", "/") + "-" + edu.end_date.replace("-", "/")}
          </p>
        </div>
      )}
    </li>
  );
};
const ListBox = ({ title, children, className }) => {
  const classes = twMerge("rounded-lg border border-neutral-300 bg-neutral-200 p-4", className);
  return (
    <div className={classes}>
      <h3 className="pb-3 text-[17px] font-semibold leading-tight text-primary-900">{title}</h3>
      {children}
    </div>
  );
};

const CvCore = forwardRef(({ user }, ref) => {
  const work_locations = [];
  const work_time = [];
  const languages = [];
  for (const [key, value] of Object.entries(user.work_location)) {
    if (value) {
      work_locations.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  for (const [key, value] of Object.entries(user.work_time)) {
    if (value) {
      work_time.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  user.languages_array.map((lang) => languages.push(`${lang.language_name} - ${lang.language_proficiency}`));
  return (
    <div id="corecv" ref={ref} className="flex flex-col gap-4">
      <SectionTitle title={"Core CV"} className={"items-start gap-1 text-[20px] max-lg:hidden"} />
      <div className="grid grid-cols-10 gap-4 max-lg:grid-cols-1">
        <ListBox className={"lg:col-span-full"} title="Hakkında">
          <ListItems item={user.user_bio} />
        </ListBox>
        <ListBox className={"lg:col-span-2"} title="Çalışma Tercihi">
          <ul className="flex flex-wrap gap-2">
            {work_locations.map((loc, index) => (
              <ListItems key={index} item={loc} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-3"} title="Çalışma Zamanı Tercihi">
          <ul className="flex flex-wrap gap-2">
            {work_time.map((option, index) => (
              <ListItems key={index} item={option} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-2"} title="Çalışma Durumu">
          <ul className="flex flex-wrap gap-2">
            <ListItems item={user.work_state === "employed" ? "Çalışıyor" : "Çalışmıyor"} />
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-3"} title="Dil Bilgisi">
          <ul className="flex flex-wrap gap-2">
            {languages.map((language, index) => (
              <ListItems key={index} item={language} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-full"} title="Yetenekler">
          <ul className="scrollbar-hide flex flex-wrap gap-2 overflow-auto lg:max-h-[250px]">
            {user.skills_array.map((skill, index) => (
              <ListItems key={index} item={skill.skill_name} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-5"} title="Eğitim Durumu">
          <ul className="scrollbar-hide flex flex-col gap-2 overflow-auto lg:max-h-[250px]">
            {user.educations_array.map((school, index) => (
              <li key={index} className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
                <div className="flex flex-1 flex-col justify-between max-lg:w-full">
                  <p className="text-[14px] font-medium leading-tight text-primary-900">
                    &#x2022; {school.institution}
                  </p>
                  <p className="text-[14px] font-medium leading-tight text-neutral-700">
                    {school.department !== "-1" && school.department + ","} {school.degree}
                  </p>
                </div>
                <p className="text-[14px] font-medium leading-tight text-neutral-700">
                  {school.start_date?.replace("-", "/") + "-" + school.end_date?.replace("-", "/")}
                </p>
              </li>
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-5"} title="Deneyimler">
          <ul className="scrollbar-hide flex flex-col gap-2 overflow-auto lg:max-h-[250px]">
            {user.experiences_array.map((exp, index) => (
              <li key={index} className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
                <div className="flex flex-1 flex-col justify-between max-lg:w-full">
                  <p className="text-[14px] font-medium leading-tight text-primary-900">&#x2022; {exp.firm_name}</p>
                  <p className="text-[14px] font-medium leading-tight text-neutral-700">{exp.job.name}</p>
                </div>
                <p className="text-[14px] font-medium leading-tight text-neutral-700">
                  {exp.start_date?.replace("-", "/") + "-" + (exp.end_date?.replace("-", "/") || "Devam Ediyor")}
                </p>
              </li>
            ))}
          </ul>
        </ListBox>
      </div>
    </div>
  );
});

export default CvCore;
