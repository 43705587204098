import React, { useEffect } from "react";
import GradientBorder from "../../../../components/NewDesign/GradientBorder";
import ExpBar from "../../../../components/NewDesign/StatusBar/ExpBar";
import { PeopleFilled, RightArrowOutlined } from "../../../../components/OldDesign/Icons";
import { AidSectorsIllustration1 } from "../../../../components/NewDesign/Illustrations";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store/useStore";

const AnalyseRow = ({ analysis }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const openAnalysisReportModal = () => {
    navigate(`/analysis/${analysis.id}`, { state: { previousLocation: location } });
  };
  return (
    <div className="flex items-center justify-between rounded-lg border border-neutral-100 bg-secondary_recruiter-100 px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)]">
      <p className="basis-[22%] font-medium leading-tight text-primary-800">{analysis.name}</p>
      <div className="flex basis-[10%] items-center gap-0.5">
        <PeopleFilled className="h-3 w-3 text-primary-900" />
        <p className="text-[13px] font-medium leading-tight text-primary-900">{analysis.seat_count}</p>
      </div>
      <div className="flex basis-[15%] flex-col">
        <span className="text-[13px] font-bold leading-tight text-chart-purple2">
          {analysis.progress_percent_total} %
        </span>
        <ExpBar className="h-2" percent={analysis.progress_percent_total} />
      </div>

      <button
        onClick={() => openAnalysisReportModal()}
        className="flex basis-[15%] items-center gap-1 text-[14px] font-bold leading-tight text-primary-600"
      >
        Uyum Raporu <RightArrowOutlined className="h-4 w-4" />
      </button>
    </div>
  );
};

const MyAnalyses = () => {
  const getJobPositionList = useStore((state) => state.getJobPositionList);
  const jobPositions = useStore((state) => state.jobPositions);

  useEffect(() => {
    if (!jobPositions.isLoaded) {
      getJobPositionList();
    }
  }, [getJobPositionList, jobPositions.isLoaded]);
  if (!jobPositions.isLoaded) {
    return null;
  }
  return (
    <div className="bg-cna-gradientblur bg-cover pt-[64px] max-lg:mb-[55px] max-lg:pt-[54px] lg:h-screen lg:w-screen lg:overflow-hidden">
      <div className="container2 h-full py-4">
        <div className="-mx-2 grid h-full grid-cols-5 grid-rows-[230px_1fr] gap-[16px_24px] px-2 max-lg:grid-cols-1">
          <GradientBorder className="col-span-1 bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] backdrop-blur-md lg:row-span-1">
            <div className="flex items-center gap-3 pb-4">
              <img src="" alt="" className="h-[77px] w-[77px] rounded-full bg-neutral-100" />
              <div className="flex flex-col gap-1">
                <h3 className="text-[18px] font-semibold leading-tight text-primary-800">Humanas</h3>
                <p className="text-sm font-medium leading-tight text-neutral-700">Izmir/Turkiye</p>
              </div>
            </div>
            <div className="pb-4">
              <div className="flex justify-between pb-1.5">
                <p className="text-sm font-medium leading-tight text-primary-700">Krediler</p>
                <span className="text-sm font-medium leading-tight text-primary-700">26/30</span>
              </div>
              <ExpBar percent={87} />
            </div>
            <div>
              <div className="flex justify-between pb-1.5">
                <p className="text-sm font-medium leading-tight text-primary-700">Tecrube Puani</p>
                <span className="text-sm font-medium leading-tight text-primary-700">170/200</span>
              </div>
              <ExpBar percent={92} />
            </div>
          </GradientBorder>

          <GradientBorder className="col-span-4 h-full bg-neutral-100 bg-opacity-50 p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.16)] backdrop-blur-md max-lg:col-span-1 lg:row-span-3">
            <div className="flex items-center justify-between pb-4">
              <h2 className="text-[20px] font-bold leading-tight text-primary-900">İş Pozisyonu Uygunluk Analizi</h2>
              <span className="rounded-full bg-primary-200 px-2 py-1 text-sm font-semibold leading-tight text-primary-600">
                {jobPositions.data.rows?.filter((positions) => positions.state === "completed").length}/
                {jobPositions.data.rows?.length}
              </span>
            </div>
            <div className="mb-4 flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2">
              <div className="basis-[22%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Job Title</p>
              </div>
              <div className="basis-[10%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Kişi Sayısı</p>
              </div>
              <div className="basis-[15%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Progress</p>
              </div>
              <div className="basis-[15%]">
                <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
              </div>
            </div>
            <div className="-mx-2 -my-1 flex max-h-[calc(100%-64px)] flex-col gap-3 overflow-y-auto px-3 py-1">
              {jobPositions.data.rows
                .filter((positions) => positions.state === "completed")
                .map((analysis, index) => (
                  <AnalyseRow key={index} analysis={analysis} />
                ))}
            </div>
          </GradientBorder>

          <GradientBorder className="col-span-1 bg-primary-800 p-6 lg:row-span-2">
            <h2 className="text-[20px] font-bold leading-tight text-neutral-100">Tam Kan Sayımı 1</h2>
            <p className="py-6 font-medium leading-tight text-primary-200">
              İş pozisyonlarınızdan istediğiniz birini seçerek analiz yapmaya başlayın!
              <br />
              <br />
              Tüm analizlerinizi tamamladığınızda, uzmanlarımız raporunuzu hazırlayacaktır.
            </p>
            <AidSectorsIllustration1 className="absolute bottom-0 right-0 w-[140px]" />
          </GradientBorder>
        </div>
      </div>
    </div>
  );
};

export default MyAnalyses;
