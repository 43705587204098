import React from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { Form } from "../../Forms/Form";
import { Field, FieldCheckbox } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { WORK_TIME } from "../../constants";
import { LeftCircularOutlined } from "../../../OldDesign/Icons";
import { Checkbox } from "../../Forms/Input";

const WorkingTimeCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);
  let defaultValue = "";
  if (coreCV.work_time) {
    defaultValue = Object.keys(coreCV.work_time).filter((key) => coreCV.work_time[key] === true);
    // console.log(defaultValue);
  }
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      work_time: defaultValue,
    },
    mode: "onSubmit",
  });

  const sendFormData = async (formData) => {
    const payload = {
      work_time: {
        parttime: formData.work_time.includes("parttime"),
        fulltime: formData.work_time.includes("fulltime"),
        freelance: formData.work_time.includes("freelance"),
      },
    };
    // console.log(formData, payload);

    await updateApplicationForm(payload, props.handleForwardForm);
  };
  const atLeastOneOptionChecked = (selectedOpts) => {
    return selectedOpts.length > 0;
  };
  return (
    <>
      <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
        <fieldset>
          <h2 className="mb-4 border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
            {content["calisma zamani tercihi"]}
          </h2>
          {/* <h4
            className={`${
              errors.work_time ? "text-error-400" : "text-neutral-900"
            } pb-2 text-[15px] leading-tight `}
          >
            Çalışma Zamanı Tercihi
          </h4> */}
          <div className="flex flex-col gap-4">
            <FieldCheckbox label="Part Time">
              <Checkbox
                value="parttime"
                {...register("work_time", {
                  validate: atLeastOneOptionChecked,
                })}
                id="parttime"
              />
            </FieldCheckbox>
            <FieldCheckbox label="Full Time">
              <Checkbox
                value="fulltime"
                {...register("work_time", {
                  validate: atLeastOneOptionChecked,
                })}
                id="fulltime"
              />
            </FieldCheckbox>
            <FieldCheckbox label="Freelance">
              <Checkbox
                value="freelance"
                {...register("work_time", {
                  validate: atLeastOneOptionChecked,
                })}
                id="freelance"
              />
            </FieldCheckbox>
          </div>
        </fieldset>
        <div className="flex justify-center gap-20">
          <button disabled={isSubmitting} type="button" id="backwardArrow" onClick={() => props.handleBackwardForm()}>
            <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
          </button>
          <button disabled={isSubmitting} type="submit" id="forwardArrow">
            <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
          </button>
        </div>
      </Form>
    </>
  );
};

export default WorkingTimeCard;
