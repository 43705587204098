import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { Input } from "../../Forms/Input";
import { useStore } from "../../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import { LeftCircularOutlined } from "../../../OldDesign/Icons";
import { addYears, subYears } from "date-fns";

const PersonalInfoCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      user_name: coreCV.user_name || "",
      user_email: coreCV.email || "",
      birth_date: coreCV.birth_date || "",
    },
    mode: "onSubmit",
  });
  const sendFormData = async (formData) => {
    let birth_date = null;
    if (formData.birth_date.length === 10) {
      birth_date = formData.birth_date;
    } else {
      let day = formData.birth_date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      // console.log(day); // 23

      let month = formData.birth_date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      // console.log(month + 1); // 8

      let year = formData.birth_date.getFullYear();
      // console.log(year); // 2022
      birth_date = year + "-" + month + "-" + day;
    }
    // if (coreCV.email?.length > 0) {
    //   delete formData.user_email;
    // }
    const payload = {
      ...formData,
      birth_date: birth_date,
    };
    // console.log(payload);
    await updateApplicationForm(payload, props.handleForwardForm);
  };
  return (
    <Form id="personalinfoform" className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
      <fieldset className="flex flex-col gap-4">
        <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
          {content["kisisel bilgiler baslik"]}
        </h2>
        <Field label={content["ad soyad baslik"]} error={errors?.user_name}>
          <Input {...register("user_name", { required: "Required" })} id="user_name" placeholder="Yazınız" />
        </Field>
        <Field label={content["eposta adresi baslik"]} error={errors?.user_email}>
          <Input
            // disabled={coreCV.email}
            // readOnly={coreCV.email}
            {...register("user_email", {
              required: "Required",
            })}
            id="user_email"
            placeholder="Yazınız"
          />
        </Field>
        <Field label={content["dogum tarihi baslik"]} error={errors?.birth_date}>
          <Controller
            control={control}
            id="birth_date"
            name="birth_date"
            rules={{
              required: "Required",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                selected={Date.parse(value)}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                minDate={subYears(new Date(), 100)}
                placeholderText="Seçiniz"
                showYearDropdown
                scrollableYearDropdown
                showMonthDropdown
                yearDropdownItemNumber={100}
                className="h-[52px] w-full rounded-lg border-[1px] border-neutral-300 p-4 focus:outline-primary-600"
              />
            )}
          />
        </Field>
      </fieldset>
      <div className="flex justify-center gap-20">
        <button disabled={true} type="button" id="backwardArrow" onClick={() => props.handleBackwardForm()}>
          <LeftCircularOutlined className="h-10 w-10 text-primary-200 " />
        </button>
        <button disabled={isSubmitting} type="submit" id="forwardArrow">
          <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
        </button>
      </div>
    </Form>
  );
};

export default PersonalInfoCard;
