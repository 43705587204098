import React, { useState } from "react";
import Tooltip from "./Tooltips/Tooltip";
import { InfoOutlined } from "../OldDesign/Icons";
import { Range } from "react-range";

const EditableOrientationCard = ({ orientation, orientationContent, editMode = false, onChange }) => {
  const indicatorLocation = orientation.direction ? 50 + orientation.value : 50 - orientation.value;

  const [rangeVal, setRangeVal] = useState([indicatorLocation]);

  const handleRangeChange = (val) => {
    setRangeVal(val);
    onChange && onChange(val[0]);
  };

  const editModeStatId =
    rangeVal[0] >= 90 || rangeVal[0] <= 10
      ? "extraordinary"
      : (rangeVal[0] < 90 && rangeVal[0] >= 75) || (rangeVal[0] <= 25 && rangeVal[0] > 10)
      ? "strong"
      : (rangeVal[0] < 75 && rangeVal[0] >= 60) || (rangeVal[0] <= 40 && rangeVal[0] > 25)
      ? "balanced"
      : "fully_balanced";
  const editModeDirection = rangeVal[0] > 50 ? "right" : "left";

  const resultDirection = editMode ? editModeDirection : orientation.direction ? "right" : "left";
  const resultStatId = editMode ? editModeStatId : orientation.stat_id;

  return (
    <div
      id={`orientationCard_${orientation.stat_id}`}
      className="rounded-xl border border-neutral-300 bg-neutral-100 p-4"
    >
      <h3 className="pb-6 text-center text-[18px] font-semibold leading-tight text-primary-900">
        {orientationContent.title}
      </h3>
      <div className="relative flex flex-col gap-4 ">
        <div className="grid grid-cols-2 gap-2">
          <div
            className={`${
              resultDirection === "left" || orientation.value === 0 || (editMode && rangeVal[0] === 50)
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{orientationContent.name_left}</span>
              <Tooltip content={orientationContent.ideal_working_environment_left}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
          <div
            className={`${
              resultDirection === "right" || orientation.value === 0 || (editMode && rangeVal[0] === 50)
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{orientationContent.name_right}</span>
              <Tooltip content={orientationContent.ideal_working_environment_right}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
        </div>
        {/* BAR */}
        <OrientationBar
          editMode={editMode}
          indicatorLocation={indicatorLocation}
          rangeVal={rangeVal}
          handleRangeChange={handleRangeChange}
        />
        <div className="w-full max-lg:pt-4">
          {/* DESCRIPTION */}
          <div
            className={`rounded-xl border border-none border-primary-800 bg-neutral-200 p-3 leading-tight  max-lg:text-[13px]`}
          >
            <h5 className="pb-3 font-bold text-neutral-800">
              <span className="text-primary-800">{orientationContent[resultStatId][resultDirection].title}</span>{" "}
              {resultStatId === "fully_balanced"
                ? orientationContent.title + " Yönelimi"
                : resultDirection === "right"
                ? orientationContent.name_right
                : orientationContent.name_left}
            </h5>
            <p className="text-[14px] font-medium leading-tight text-neutral-800">
              {orientationContent[resultStatId][resultDirection].description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrientationBar = ({ handleRangeChange, indicatorLocation, rangeVal, editMode = false }) => {
  return (
    <div className="relative w-full lg:mb-6">
      <div className="grid h-6 grid-cols-[repeat(20,minmax(0,1fr))] gap-1 max-lg:h-4 max-lg:gap-1">
        <div
          className={`${
            editMode && rangeVal[0] > 10 ? "grayscale" : "grayscale-0"
          } relative col-span-2 grid grid-cols-2 transition-all duration-500`}
        >
          <div className="rounded-l-full bg-[#135B91]"></div>
          <div className="bg-[#3469AD]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Sıra Dışı
          </span>
        </div>
        <div
          className={`${
            editMode && (rangeVal[0] <= 10 || rangeVal[0] > 25) ? "grayscale" : "grayscale-0"
          } relative col-span-3 grid grid-cols-3 transition-all duration-500`}
        >
          <div className="bg-[#4771BD]"></div>
          <div className="bg-[#607CD3]"></div>
          <div className="bg-[#7986E8]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Güçlü
          </span>
        </div>
        <div
          className={`${
            editMode && (rangeVal[0] <= 25 || rangeVal[0] > 40) ? "grayscale" : "grayscale-0"
          } relative col-span-3 grid grid-cols-3 transition-all duration-500`}
        >
          <div className="bg-[#8D94F8]"></div>
          <div className="bg-[#86A9F0]"></div>
          <div className="bg-[#82B7EA]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Dengeli
          </span>
        </div>
        <div
          className={`${
            editMode && (rangeVal[0] <= 40 || rangeVal[0] >= 60) ? "grayscale" : "grayscale-0"
          } relative col-span-4 grid grid-cols-4 transition-all duration-500`}
        >
          <div className="bg-[#7CCBE3]"></div>
          <div className="bg-[#77DEDB]"></div>
          <div className="bg-[#77DEDB]"></div>
          <div className="bg-[#7CCBE3]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Tam Dengeli
          </span>
        </div>
        <div
          className={`${
            editMode && (rangeVal[0] < 60 || rangeVal[0] >= 75) ? "grayscale" : "grayscale-0"
          } relative col-span-3 grid grid-cols-3 transition-all duration-500`}
        >
          <div className="bg-[#82B7EA]"></div>
          <div className="bg-[#86A9F0]"></div>
          <div className="bg-[#8D94F8]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Dengeli
          </span>
        </div>
        <div
          className={`${
            editMode && (rangeVal[0] < 75 || rangeVal[0] >= 90) ? "grayscale" : "grayscale-0"
          } relative col-span-3 grid grid-cols-3 transition-all duration-500`}
        >
          <div className="bg-[#7986E8]"></div>
          <div className="bg-[#607CD3]"></div>
          <div className="bg-[#4771BD]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Güçlü
          </span>
        </div>
        <div
          className={`${
            editMode && rangeVal[0] < 90 ? "grayscale" : "grayscale-0"
          } relative col-span-2 grid grid-cols-2 transition-all duration-500`}
        >
          <div className="bg-[#3469AD]"></div>
          <div className="rounded-r-full  bg-[#135B91]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Sıra Dışı
          </span>
        </div>
      </div>

      {!editMode && (
        <div
          className={`absolute -left-[17px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
          style={{ paddingLeft: `${indicatorLocation}%` }}
        >
          {/* INDICATOR */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="24"
            viewBox="0 0 33 24"
            fill="none"
            className="max-lg:w-5"
          >
            <g filter="url(#filter0_d_5473_28772)">
              <path
                d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                fill="white"
              />
              <path
                d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                stroke="#6070CF"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_5473_28772"
                x="0.845703"
                y="0.953125"
                width="31.7227"
                height="22.4292"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
              </filter>
            </defs>
          </svg>
        </div>
      )}
      {editMode && (
        <Range
          //   label="Select your value"
          step={1}
          min={0}
          max={100}
          values={rangeVal}
          onChange={(values) => handleRangeChange(values)}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                position: "absolute",
                zIndex: "10",
                top: "-4px",
                height: "30px",
                width: "100%",
                backgroundColor: "#CCCCCC00",
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
              {...props}
              style={{ ...props.style, position: "absolute", top: "-13px", outline: "none" }}
              className="max-lg:w-5"
            >
              <g filter="url(#filter0_d_5473_28772)">
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  fill="white"
                />
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  stroke="#6070CF"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5473_28772"
                  x="0.845703"
                  y="0.953125"
                  width="31.7227"
                  height="22.4292"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
                </filter>
              </defs>
            </svg>
          )}
        />
      )}
      <div className="absolute right-1/2 top-1/2 h-4 w-4 -translate-y-1/2 translate-x-1/2 rotate-45 rounded bg-neutral-100 max-lg:h-3 max-lg:w-3 max-lg:rounded-[2px]"></div>
    </div>
  );
};

export default EditableOrientationCard;
