import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../Forms/Form";
import VerificationInput from "react-verification-input";
import CountdownTimer from "../../OldDesign/CountdownTimer";
import Button from "../Button/Button";
import { Field } from "../Forms/Field";
import { useStore } from "../../../store/useStore";
import { PhoneInput } from "react-international-phone";
import SectionTitle from "../SectionTitle";
import isPhoneNumValid from "../../../utils/isPhoneNumValid";

const PhoneConfirmationForm = () => {
  const navigate = useNavigate();
  const coreCV = useStore((state) => state.coreCV.data);
  const coreCVPhoneConfirmation = useStore((state) => state.coreCVPhoneConfirmation);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);
  const confirmationAction = useStore((state) => state.confirmationAction);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      confirmation_phone: coreCV.phone || "",
      confirmation_code: "",
    },
    mode: "onSubmit",
  });
  const sendConfirmationCode = (formData) => {
    coreCVPhoneConfirmation(formData);
    // console.log(formData);
  };
  const resendCode = () => {
    coreCVPhoneConfirmation({
      confirmation_phone: getValues("confirmation_phone"),
    });
  };

  return (
    <section>
      <SectionTitle className="items-start" title={content["telefon numarasi dogrulamasi baslik"]} />
      <Form className="flex flex-col justify-between" onSubmit={handleSubmit(sendConfirmationCode)}>
        {confirmationAction.params?.location === "applicationform" ? (
          <>
            <fieldset className="flex flex-col gap-6 py-3">
              <p className="text-[20px] font-medium text-neutral-800">{content["dogrulama kodu gir aciklama"]}</p>
              <Controller
                control={control}
                name="confirmation_code"
                rules={{
                  required: "Required",
                  minLength: {
                    value: 6,
                    message: "Sorry, wrong code.",
                  },
                  maxLength: {
                    value: 6,
                    message: "Sorry, wrong code.",
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { ...fieldState } }) => (
                  <VerificationInput
                    onChange={onChange}
                    errors={errors}
                    {...fieldState}
                    ref={ref}
                    placeholder=""
                    validChars="0-9"
                    classNames={{
                      container: "w-[310px] h-[46px] lg:w-[400px] lg:h-[60px]",
                      character: `${
                        errors.confirmation_code
                          ? "text-error-400 border-error-400"
                          : "text-primary-600 border-primary-200"
                      } bg-neutral-100 rounded-md outline-none text-[36px] font-bold flex items-center justify-center`,
                      characterSelected: `character-selected-verification-input ${
                        errors.confirmation_code ? "border-error-400" : "border-primary-600"
                      }`,
                      characterInactive: ` ${errors.confirmation_code ? "border-error-400" : "border-primary-200"}`,
                    }}
                  />
                )}
              />
            </fieldset>
            <div className="flex flex-col gap-3 ">
              <div className="self-start">
                <CountdownTimer expire_time={parseInt(confirmationAction.params.parameters.expires_in)} />
                {/* {expireTime} */}
              </div>
              <div>
                <Button>{content["gonder buton"]}</Button>
              </div>
              <p className="self-center text-[14px] leading-tight text-primary-700">
                {content["kod ulasmadi mi aciklama"]}{" "}
                <button type="button" onClick={() => resendCode()} className="font-semibold">
                  {content["tekrar gonder aciklama"]}
                </button>
              </p>
            </div>
          </>
        ) : (
          <>
            <fieldset className="flex flex-col gap-6 py-3">
              <p className="text-[20px] font-medium text-neutral-800">{content["devam et aciklama"]}</p>
              <div className="w-1/2">
                <Field label={content["telefon numarasi baslik"]} error={errors?.confirmation_phone}>
                  <Controller
                    control={control}
                    name="confirmation_phone"
                    rules={{
                      required: "Required",
                      validate: (number) => isPhoneNumValid(number),
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PhoneInput
                        defaultCountry="tr"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        forceDialCode
                        inputClassName="border"
                        className="border-1 rounded-lg border border-neutral-300  hover:border-primary-600 focus:border-4 focus:border-primary-600"
                        inputProps={{
                          className: "pl-3 h-[49px] bg-neutral-100 text-[15px] lg:h-[52px] lg:text-[16px] outline-none",
                        }}
                        countrySelectorStyleProps={{
                          className: "bg-neutral-200 rounded-l-lg",
                          buttonClassName: "border-none h-full rounded-l-lg hover:rounded-l-lg px-3",
                        }}
                      />
                    )}
                  />
                </Field>
              </div>
            </fieldset>
            <div className="flex flex-col gap-6">
              <p className="text-[13px] leading-tight text-primary-700">{content["kvkk metni baslik"]}</p>
              <div>
                <Button>{content["gonder buton"]}</Button>
              </div>
            </div>
          </>
        )}
      </Form>
    </section>
  );
};

export default PhoneConfirmationForm;
