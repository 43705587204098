import React from "react";
import { twMerge } from "tailwind-merge";

const SectionTitle = ({ title, ...props }) => {
  const className =
    "max-lg:text-[20px] w-full flex flex-col items-center justify-center gap-[10px] text-[28px] text-primary-900 font-semibold leading-tight";
  const mergedClasses = twMerge(className, props.className);
  return (
    <div className={mergedClasses}>
      {title}
      <div className="w-1/4 max-w-[40px] rounded-sm border-b-[2px] border-primary-900"></div>
    </div>
  );
};

export default SectionTitle;
