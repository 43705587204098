import React, { useRef, useState } from "react";
import { FilterFilled } from "../OldDesign/Icons";
import { Form } from "../NewDesign/Forms/Form";
import { Field } from "../NewDesign/Forms/Field";
import { ASelect, SSelect } from "./Forms/Input";
import { EXPERIENCE } from "./constants";
import Button from "./Button/Button";
import { useStore } from "../../store/useStore";
import { REACT_BASE_URL } from "../../api/constants";
const INIT_FILTERS = {
  country: "",
  city: [],
  jobtitle: [],
  skills: [],
  experience: [],
};
const FilterBar = () => {
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState(INIT_FILTERS);
  //
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const loadCountryOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/country?page=1&rows=20&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const loadCityOptions = async (value) => {
    const selectedCountry = filter.country.id;
    const apiKey = localStorage.getItem("token");
    const res = await fetch(
      `${REACT_BASE_URL}/content/state?page=1&rows=60&keyword=${value}&country_id=${selectedCountry}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const loadSkillsOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/skill?rows=100&page=1&keyword=${encodeURIComponent(value)}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  //
  const handleSingleSelection = (e, a) => {
    setFilter((prev) => ({ ...prev, [a.name]: e }));
  };
  const handleMultiSelection = (e, a) => {
    setFilter((prev) => ({ ...prev, [a.name]: [...prev.city, e] }));
  };

  return (
    <div className="relative">
      <button
        onClick={() => setOpen(!open)}
        disabled={true}
        className={`${
          open ? "bg-primary-600 text-primary-75" : "bg-primary-75 text-primary-600"
        } flex items-center gap-1.5 rounded-full p-3`}
      >
        <FilterFilled className="h-4 w-4 text-neutral-400 " />
      </button>

      {open ? (
        <div className="absolute right-0 top-10 z-10 w-[450px] rounded-xl border border-secondary_recruiter-100 bg-neutral-100 shadow-[0px_0px_12px_0px_rgba(13,21,90,0.08)]">
          <p className="p-6 text-[18px] font-bold leading-tight text-primary-900">Filters</p>
          <Form
            ref={formRef}
            className="mb-2 flex max-h-[430px] flex-1 flex-col gap-6 overflow-auto overflow-x-hidden px-6"
          >
            {/* <div>{JSON.stringify(filter)}</div> */}
            <Field label="Country">
              <ASelect
                name="country"
                loadOptions={loadCountryOptions}
                menuShouldScrollIntoView
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.id}
                placeholder="Select..."
                onChange={handleSingleSelection}
              />
            </Field>
            <Field label="City">
              <ASelect
                key={`city_key_${filter.city.length}`}
                name="city"
                isDisabled={filter.country === ""}
                loadOptions={loadCityOptions}
                menuShouldScrollIntoView
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.id}
                placeholder={filter.city.length > 0 ? `${filter.city.length} City selected` : "Select..."}
                onChange={handleMultiSelection}
              />
            </Field>
            <Field label="Job Title">
              <ASelect
                key={`jobtitle_key_${filter.jobtitle.length}`}
                name="jobtitle"
                loadOptions={loadJobTitleOptions}
                menuShouldScrollIntoView
                getOptionLabel={(opt) => opt.job_name}
                getOptionValue={(opt) => opt.job_id}
                placeholder={filter.jobtitle.length > 0 ? `${filter.jobtitle.length} Job Title selected` : "Select..."}
                onChange={handleMultiSelection}
              />
            </Field>
            <Field label="Skills">
              <ASelect
                key={`skills_key_${filter.skills.length}`}
                name="skills"
                loadOptions={loadSkillsOptions}
                menuShouldScrollIntoView
                getOptionLabel={(opt) => opt.skill_name}
                getOptionValue={(opt) => opt.skill_id}
                placeholder={filter.skills.length > 0 ? `${filter.skills.length} Skills selected` : "Select..."}
                onChange={handleMultiSelection}
              />
            </Field>
            <Field label="Experience">
              <SSelect
                key={`experience_key_${filter.experience.length}`}
                name="experience"
                options={EXPERIENCE}
                menuShouldScrollIntoView
                getOptionLabel={(opt) => opt.job_experience_title}
                getOptionValue={(opt) => opt.job_experience_id}
                placeholder={
                  filter.experience.length > 0 ? `${filter.experience.length} Experience selected` : "Select..."
                }
                onChange={handleMultiSelection}
              />
            </Field>
          </Form>
          <div className="flex items-center gap-4 rounded-b-xl bg-neutral-100 p-6 shadow-[0px_-2px_12px_0px_rgba(13,21,90,0.04)]">
            <Button className="flex-1">
              <FilterFilled className="h-5 w-5 text-neutral-100" />
              Apply Filters
            </Button>
            <Button className="flex-1 bg-neutral-100 text-primary-600">Clear Filters</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterBar;
