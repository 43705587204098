import React from "react";
import { CNA_NAV_LINKS } from "../../../components/NewDesign/constants";
import { NavLink } from "react-router-dom";
import { ChangeFilled, MailFilled, PhoneFilled } from "../../../components/OldDesign/Icons";
import Button from "../../../components/NewDesign/Button/Button";
import Loading from "../../../components/OldDesign/Loading";

const CNANavigationMenu = ({ cnaDashboard }) => {
  if (!cnaDashboard.isLoaded) {
    return (
      <div className="lg:h-[calc(100vh-96px)] lg:max-h-[800px]">
        <Loading className={"h-full w-full"} />
      </div>
    );
  }
  return (
    <div className="lg:h-[calc(100vh-96px)] lg:max-h-[800px]">
      <div className="-left-9 top-0 flex h-full flex-col gap-6 bg-neutral-100 bg-opacity-50 p-6 pl-9 backdrop-blur-sm max-lg:overflow-x-auto max-lg:bg-transparent max-lg:p-0 lg:absolute lg:w-[334px]">
        <div className="flex flex-col gap-3 max-lg:hidden">
          <div className="flex items-center gap-1.5">
            <img src={cnaDashboard.data.company_profile_picture} className="h-12 w-12 rounded-full" alt="" />
            <p className="text-lg font-semibold leading-tight text-primary-900">{cnaDashboard.data.company_name}</p>
          </div>
          <div className="flex items-center justify-center divide-x">
            {cnaDashboard.data.stats.map((stat, index) => {
              return (
                <div key={index} className="flex flex-1 flex-col items-center gap-1 px-1">
                  <span>{stat.description}</span>
                  <p className="text-xs font-semibold leading-tight text-neutral-700">{stat.name}</p>
                </div>
              );
            })}
            {/* <div className="flex flex-1 flex-col items-center gap-1 px-1">
                  <span>12</span>
                  <p className="text-sm leading-tight text-neutral-700">Kişi Sayısı</p>
                </div>
                <div className="flex flex-1 flex-col items-center gap-1 px-1">
                  <span>5842</span>
                  <p className="text-sm leading-tight text-neutral-700">Analiz</p>
                </div> */}
          </div>
        </div>
        <hr className="max-lg:hidden" />
        <div className="flex flex-col gap-2 max-lg:flex-row">
          {CNA_NAV_LINKS.map((link) => {
            return (
              <NavLink
                key={link.path}
                to={link.path}
                style={({ isActive, isPending }) => {
                  return {
                    background: isActive ? "#106BAC" : "#00000000",
                  };
                }}
                className={
                  "flex items-center gap-3 rounded-xl px-4 py-3 text-base font-semibold max-lg:gap-1.5 max-lg:p-1.5"
                }
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <>
                    {isActive ? link.icons.active : link.icons.passive}
                    <span
                      className={`${
                        isActive ? "text-neutral-100" : "text-neutral-700"
                      } w-[calc(100%)] overflow-hidden text-ellipsis whitespace-nowrap`}
                    >
                      {link.title}
                    </span>
                  </>
                )}
              </NavLink>
            );
          })}
        </div>
        <hr className="max-lg:hidden" />
        <div className="flex flex-col gap-2 rounded-xl border border-primary-300 p-4 max-lg:hidden">
          <div className="flex flex-col gap-1">
            <p className="font-semibold leading-tight text-primary-900">{cnaDashboard.data.consultants[0].name}</p>
            <p className="text-sm leading-tight text-neutral-700">{cnaDashboard.data.consultants[0].description}</p>
            <hr />
          </div>
          <ul className="flex flex-col gap-1">
            <li className="flex items-center gap-1">
              <PhoneFilled className={"h-4 w-4 text-primary-800"} />
              <span className="text-sm leading-tight text-primary-800">{cnaDashboard.data.consultants[0].phone}</span>
            </li>
            <li className="flex items-center gap-1">
              <MailFilled className={"h-4 w-4 text-primary-800"} />
              <span className="text-sm leading-tight text-primary-800">{cnaDashboard.data.consultants[0].email}</span>
            </li>
          </ul>
          <Button
            className="hidden border-none bg-transparent p-0 font-bold text-primary-600"
            icon={<ChangeFilled className={"h-4 w-4 text-primary-600"} />}
          >
            Danışmanı Değiştir
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CNANavigationMenu;
