import React, { useState } from "react";
import { Checkbox } from "./Forms/Input";
import {
  BagIconFilled,
  Dots3Filled,
  DownloadCircleOutlined,
  EmployeeFilled,
  HeartFilled,
  HeartOutlined,
  SendFilled,
} from "../OldDesign/Icons";
import { TagWithIcon } from "./Tags";
import Button from "./Button/Button";
import userPP from "../../user.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { Link } from "react-router-dom";

const DotMenu = ({ dotMenuOpen, setDotMenuOpen, user, setActionCount }) => {
  const archiveMetaCv = useStore((state) => state.archiveMetaCv);
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const userIsArchived = user.all_sources.includes("archives");

  const handleArchiveUser = async (e) => {
    e.stopPropagation();
    try {
      userIsArchived ? await archiveMetaCv(user.id, false) : await archiveMetaCv(user.id);
    } catch (err) {
      console.log(err);
    } finally {
      setActionCount((prev) => prev + 1);
    }
  };

  return (
    <div className="relative self-start">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setDotMenuOpen(true);
        }}
        onBlur={(e) => {
          e.stopPropagation();
          setTimeout(() => {
            setDotMenuOpen(false);
          }, 100);
        }}
        tabIndex={0}
        className="rounded-lg py-2 transition-colors ease-in hover:bg-primary-75"
      >
        <Dots3Filled className="h-6 w-6" />
      </button>
      {dotMenuOpen && (
        <>
          <button
            onClick={(e) => handleArchiveUser(e)}
            className="absolute right-0 top-10 z-20 whitespace-nowrap rounded-lg bg-neutral-200 p-2 text-[14px]"
          >
            {userIsArchived ? "Arşivden Çıkar" : content["kart buton arsiv"]}
          </button>
          <div
            className="fixed left-0 top-0 z-10 h-screen w-screen opacity-0"
            onClick={() => {
              setDotMenuOpen(false);
            }}
          ></div>
        </>
      )}
    </div>
  );
};

const UserCardActionBar = ({ user, handleModalOpen, setActionCount }) => {
  const [actionLoading, setActionLoading] = useState(false);
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const unlockMetaCv = useStore((state) => state.unlockMetaCv);
  const handleFavMetaCv = useStore((state) => state.handleFavMetaCv);
  const removeFavMetaCv = useStore((state) => state.removeFavMetaCv);
  const downloadMetaCv = useStore((state) => state.downloadMetaCv);
  const archiveMetaCv = useStore((state) => state.archiveMetaCv);
  const userIsArchived = user.all_sources.includes("archives");
  const handleUnlockUser = (e) => {
    e.stopPropagation();
    unlockMetaCv(user.id);
  };
  const handleFavorite = (e) => {
    e.stopPropagation();
    handleFavMetaCv(user.id);
  };
  const removeFavorite = (e) => {
    e.stopPropagation();
    removeFavMetaCv(user.id);
  };
  const openCommentSection = (e) => {
    e.stopPropagation();
    handleModalOpen(e);
  };
  const handleMetaCvDownload = (e) => {
    downloadMetaCv(user.id, user.name);
  };
  const handleUnarchive = async (e) => {
    setActionLoading(true);
    try {
      await archiveMetaCv(user.id, false);
    } catch (err) {
      console.log(err);
    } finally {
      setActionCount((prev) => prev + 1);
      setActionLoading(false);
    }
  };
  if (userIsArchived) {
    return (
      <Button
        disabled={actionLoading}
        loading={actionLoading}
        onClick={(e) => handleUnarchive(e)}
        className={"flex-1 bg-neutral-100 p-[12.5px] text-primary-600"}
      >
        {"Arşivden Çıkar"}
      </Button>
    );
  }

  return (
    <>
      {!user.is_unlocked ? ( //while locked...
        <Button type="button" onClick={handleUnlockUser} className="flex-1 bg-neutral-100 p-[12.5px] text-primary-600">
          {content["kart send unlock request"]}
        </Button>
      ) : (
        <>
          <Button
            type="button"
            onClick={(e) => openCommentSection(e)}
            className="flex-1 bg-neutral-100 p-[12.5px] text-primary-600"
          >
            {/* {content["kart yorum yap"]} */}
            İncele
          </Button>
          <Button
            onClick={(e) => handleMetaCvDownload(e)}
            className="border-primary-75 bg-primary-75 p-[12.5px] text-primary-500"
          >
            <DownloadCircleOutlined className="h-5 w-5" />
          </Button>
        </>
      )}

      <Button
        onClick={user.all_sources.includes("favourites") ? removeFavorite : handleFavorite}
        className="border-secondary_recruiter-200 bg-secondary_recruiter-200 p-[12.5px] text-secondary_recruiter-600"
      >
        {user.all_sources.includes("favourites") ? (
          <HeartFilled className="h-5 w-5" />
        ) : (
          <HeartOutlined className="h-5 w-5" />
        )}
      </Button>
    </>
  );
};

const UserCard = ({ checked, user, props, setActionCount }) => {
  // const selectedUsers = useStore((state) => state.selectedUsers);
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [dotMenuOpen, setDotMenuOpen] = useState(false);

  const toggleUserInSelectedUsers = useStore((state) => state.toggleUserInSelectedUsers);

  const handleModalOpen = (e) => {
    navigate(`/view/${user.id}`, {
      state: { previousLocation: location },
    });
  };

  const toggleCheckbox = (e) => {
    e.stopPropagation();
    // setChecked((prev) => !prev);
    toggleUserInSelectedUsers(user);
  };

  return (
    <>
      <div
        className={`${
          checked ? "border-primary-500 shadow-xl" : ""
        } relative flex flex-col rounded-xl border p-3.5 transition-all duration-500`}
        {...props}
      >
        {/* profile */}
        <div className="flex items-center gap-2.5 border-b  pb-3 shadow-[0px_10px_8px_-8px_rgba(0,0,0,0.08)]">
          <label onClick={(e) => e.stopPropagation()} htmlFor={`card-id-${user.id}`}>
            <Checkbox
              onChange={toggleCheckbox}
              checked={checked}
              name={`card-id-${user.id}`}
              id={`card-id-${user.id}`}
            />
          </label>
          <div
            // onClick={(e) => handleModalOpen(e)}
            className="relative flex h-[58px] w-[58px] shrink-0 grow-0 items-center justify-center rounded-full bg-[#87C4FF] "
          >
            <img
              src={userPP}
              alt="profile"
              onClick={(e) => handleModalOpen(e)}
              className="h-[50px] w-[50px] cursor-pointer rounded-full"
            />
            <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[8px] font-semibold text-neutral-100">
              LVL {user.metaCvLevel}
            </span>
          </div>
          <div
            // onClick={(e) => handleModalOpen(e)}
            className="flex flex-col gap-[3px] text-left max-lg:max-w-[190px] lg:min-w-[140px]"
          >
            <p
              title={user.name}
              className="w-full overflow-hidden text-ellipsis whitespace-nowrap font-medium leading-tight text-primary-900"
            >
              {user.name}
            </p>
            <p
              title={user.job + "•" + user.state + "," + user.country}
              className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[13px] leading-tight text-neutral-900"
            >
              {user.job} • {user.state}, {user.country}
            </p>
            <div className="h-[32px] w-full overflow-hidden text-clip whitespace-nowrap ">
              {user.aid.map((badge, index) => (
                <Link
                  className="rounded-full py-1 pr-3 leading-tight transition-all hover:bg-neutral-200"
                  key={index}
                  to={`/aid/identities/${badge.aid}`}
                >
                  <img
                    src={process.env.REACT_APP_STRAPI_BASE_URL + badge.media}
                    alt={badge.name}
                    title={badge.name}
                    className="mr-1 h-[32px] w-[32px]"
                  />
                  <h1 className="gradient-text inline-block text-[14px] font-semibold leading-tight text-transparent opacity-80 max-lg:text-[13px]">
                    {badge.name} - {badge.title}
                  </h1>
                </Link>
              ))}
            </div>
          </div>
          <div className="ml-auto">
            <DotMenu
              setDotMenuOpen={setDotMenuOpen}
              dotMenuOpen={dotMenuOpen}
              user={user}
              setActionCount={setActionCount}
            />
          </div>
        </div>
        {/* tags */}
        <div className="flex gap-2 pb-2 pt-4">
          <TagWithIcon
            icon={
              user.is_employee ? (
                <EmployeeFilled className="h-[14px] w-[14px]" />
              ) : (
                <SendFilled className="h-[14px] w-[14px]" />
              )
            }
            className={`bg-secondary_recruiter-100 px-2 py-1 text-secondary_recruiter-700`}
            text={`${user.is_employee ? "Employee" : "Candidate"}`}
          />
          <TagWithIcon
            icon={<BagIconFilled className="h-[14px] w-[14px]" />}
            className={`${
              user.work_state === "unemployed" ? "bg-success-100 text-success-400" : "bg-error-100 text-error-300"
            }  px-2 py-1 `}
            text={`${user.work_state === "unemployed" ? "Not working" : "Working"}`}
          />
        </div>
        {/* skills */}
        <div className="pb-2 max-lg:hidden">
          <h5 className="pb-2 text-[13px] font-bold leading-tight text-primary-900">{content["kart skills"]}</h5>
          <div className="line-clamp-2 min-w-[270px]">
            {user.skills.map((item, index) => (
              <p
                key={index}
                title={item}
                className="line-clamp-1 inline-block max-w-[50%] items-center gap-2 text-ellipsis whitespace-nowrap pr-2 text-[14px] font-medium leading-tight text-primary-900"
              >
                •{item}
              </p>
            ))}
          </div>
        </div>
        {/* personality types */}
        <div className="mt-auto pb-2">
          <h5 className="pb-2 text-[13px] font-bold leading-tight text-primary-900 max-lg:hidden">
            {content["kart personality type"]}
          </h5>
          <div className="flex flex-row flex-wrap gap-1 max-lg:gap-1">
            {user.personalityType.map((item, index) => (
              <TagWithIcon
                key={index}
                text={item.title}
                className="bg-secondary_recruiter-600 px-2 py-1 shadow-sm shadow-secondary_recruiter-600"
              />
            ))}
          </div>
        </div>
        <div className="flex gap-1 pt-3 max-lg:pt-2">
          {/* actions */}
          <UserCardActionBar setActionCount={setActionCount} handleModalOpen={handleModalOpen} user={user} />
        </div>
      </div>
    </>
  );
};

export default UserCard;
