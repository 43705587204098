import React, { forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import OrientationCard from "../OrientationCard";

const ProfessionalOrientationSection = forwardRef(({ user }, ref) => {
  return (
    <div id="orientation" ref={ref} className="flex flex-col gap-4">
      <SectionTitle title="Mesleki Yönelim" className="items-start gap-1 pb-2 text-[22px] max-lg:hidden" />
      {/* <p className="pb-9 leading-tight text-primary-900">
        Aşağıda mesleki yönelimlerim ile ilgili sonuçları bulabilirsiniz.
      </p> */}
      <div className="flex flex-col gap-6">
        {user.metacv.dimensions.map((orientation) => {
          return <OrientationCard key={orientation.title} orientation={orientation} />;
        })}
      </div>
    </div>
  );
});

export default ProfessionalOrientationSection;
