import React, { useEffect, useState } from "react";
import { useStore } from "../../../store/useStore";
import { getNetworkList } from "../../../api/endpoints";
import userPP from "../../../user.png";
import Loading from "../../../components/OldDesign/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import emptyusers_aid from "../../../assets/emptyusers_aid.png";
import { QuotationMark } from "../../../components/NewDesign/Illustrations";
import { TagWithIcon } from "../../../components/NewDesign/Tags";

const HumanasIds = () => {
  const strapiContent = useStore((state) => state.strapiContent);
  const [selectedAid, setSelectedAid] = useState(strapiContent.data[0].attributes.codeName);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedAidContent = strapiContent.data.find((item) => item.attributes.codeName === selectedAid);
  const handleAidSelect = (aid) => {
    setSelectedAid(aid);
  };

  const handleModalOpen = (user) => {
    navigate(`/view/${user.id}`, {
      state: { previousLocation: location },
    });
  };

  useEffect(() => {
    const payload = {
      type: "connections",
      page: 1,
      rows: 24,
      keyword: "",
      search: {
        scope: "self",
        filters: {
          usergroups: [],
          usersource: [],
          work_info: [],
          personality: [],
          badge: [selectedAid],
        },
        sort: null,
      },
    };
    getNetworkList(
      payload,
      (res) => setFilteredUsers(res.data.rows),
      (err) => console.log(err),
      (bool) => setLoading(bool),
    );
  }, [selectedAid]);
  return (
    <div className="grid h-full gap-4 lg:grid-cols-[313px,1fr]">
      <select
        onChange={(e) => handleAidSelect(e.target.value)}
        name="aid"
        id="aid"
        className="rounded-lg p-2 font-semibold lg:hidden"
      >
        {strapiContent.data.map((aid, index) => {
          return (
            <option key={index} value={aid.attributes.codeName}>
              {aid.attributes.name} {aid.attributes.title}
            </option>
          );
        })}
      </select>
      <div className="divide-y divide-neutral-100 rounded-xl bg-neutral-100 bg-opacity-30 max-lg:hidden">
        {strapiContent.data.map((aid, index) => {
          return (
            <AidListItem
              key={index}
              aid={aid}
              onClick={() => handleAidSelect(aid.attributes.codeName)}
              active={selectedAid === aid.attributes.codeName}
            />
          );
        })}
      </div>
      <div className="flex flex-col items-center justify-start">
        <div className="relative flex w-full flex-col gap-1.5 rounded-lg bg-primary-800 bg-opacity-30 px-4 py-2 max-lg:w-full max-lg:px-3 max-lg:py-1">
          <QuotationMark className={"absolute left-1 top-0 w-5 text-primary-800"} />
          <QuotationMark className={"absolute right-1 top-0 w-5 rotate-180 text-primary-800"} />
          <p className="px-2 text-center text-[13px] font-medium leading-tight text-primary-800 max-lg:text-[13px]">
            {selectedAidContent.attributes.definition}
          </p>
          <hr />
          <div className="flex flex-wrap gap-3 max-lg:justify-center max-lg:gap-2">
            {selectedAidContent.attributes.keyword.map((tag, index) => (
              <TagWithIcon
                key={index}
                text={tag.keyword}
                className={`border border-neutral-100 bg-neutral-100 bg-opacity-20 px-2 py-1 text-neutral-100`}
              />
            ))}
          </div>
        </div>
        {loading ? (
          <Loading className={"h-full w-full"} />
        ) : (
          <>
            {filteredUsers.length < 1 ? (
              <div className="flex flex-1 flex-col items-center justify-center gap-1">
                <img src={emptyusers_aid} alt="" />
                <p className="text-sm font-semibold text-primary-800">
                  Henüz bu kimliğe sahip bağlantınız bulunmamaktadır.
                </p>
              </div>
            ) : (
              <div className="flex flex-wrap gap-2.5 self-start pt-2">
                {filteredUsers.map((user, index) => {
                  return (
                    <div
                      onClick={() => handleModalOpen(user)}
                      key={index}
                      className="flex h-[100px] w-[353px] cursor-pointer items-center  gap-2 rounded-xl border border-neutral-300 bg-neutral-100 px-6 py-4 max-lg:p-3"
                    >
                      <div className="relative flex h-[65px] w-[65px] shrink-0 items-center justify-center rounded-full bg-[#069BF8] max-lg:h-[65px] max-lg:w-[65px]">
                        <img
                          src={user.profile_picture || userPP}
                          alt="profile"
                          className="h-[58px] w-[58px] rounded-full max-lg:h-[57px] max-lg:w-[57px]"
                        />
                        <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-2 text-xs  font-semibold text-neutral-100 max-lg:px-1.5 max-lg:text-[11px]">
                          LVL {user.metacv_level}
                        </span>
                      </div>
                      <div className="flex w-[calc(100%-116px)] flex-col gap-0.5">
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap font-medium leading-tight text-primary-800">
                          {user.name}
                        </p>
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-tight text-neutral-900">
                          {user.job}
                        </p>
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-tight text-neutral-900">
                          {user.state}, {user.country}
                        </p>
                      </div>
                      <div className="flex shrink-0 flex-row flex-wrap gap-1">
                        {user.aid.map((badge, index) => (
                          <img
                            key={index}
                            title={badge.level + ". Seviye " + badge.name}
                            src={process.env.REACT_APP_STRAPI_BASE_URL + badge.media}
                            alt={badge.name}
                            className="h-[35px] w-[35px]"
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const AidListItem = ({ onClick, aid, active }) => {
  return (
    <div className={`cursor-pointer`} onClick={onClick}>
      <div
        className={`${
          active ? "border-secondary_recruiter-600 bg-neutral-100 shadow-md" : "border-transparent bg-transparent"
        } flex  items-center gap-2 rounded-xl border-[1.5px] p-2`}
      >
        <div
          style={{
            background: `linear-gradient(180deg, ${aid.attributes.primaryColor} 0%, #FFFFFF00 100%)`,
          }}
          className={`h-8 w-8 rounded-full shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-6 max-lg:w-6`}
        >
          <img
            src={process.env.REACT_APP_STRAPI_BASE_URL + aid.attributes.logo.data.attributes.url}
            alt={aid.attributes.name}
          />
        </div>
        <div className="flex items-center gap-1">
          <p className="font-semibold leading-tight text-neutral-800">{aid.attributes.name}</p>
          <p className="text-sm leading-tight text-neutral-800">{aid.attributes.title}</p>
        </div>
      </div>
    </div>
  );
};

export default HumanasIds;
