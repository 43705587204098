import React, { useEffect, useState } from "react";
import ExpBar from "../../../components/NewDesign/StatusBar/ExpBar";
import { PeopleFilled, PlusCircularOutlined, RightArrowOutlined } from "../../../components/OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../store/useStore";
import Button from "../../../components/NewDesign/Button/Button";
import Pagination from "../../../components/OldDesign/Pagination";
import Loading from "../../../components/OldDesign/Loading";
import emptyusers_aid from "../../../assets/emptyusers_aid.png";

const ReportRow = ({ position }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const openAnalysisReportModal = () => {
    if (position.seat_metacv_count > 0) {
      navigate(`/analysis/${position.id}`, { state: { previousLocation: location } });
    } else {
      //if no metacv, navigate to ideal page
      navigate(`/cna/my-positions/${position.id}`);
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-between rounded-lg border border-neutral-100 bg-secondary_recruiter-100 px-4 py-2.5 shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)] max-lg:gap-3">
      <p className="basis-[22%] font-medium leading-tight text-primary-800 max-lg:basis-1/3">{position.name}</p>
      <div className="flex basis-[10%] items-center gap-0.5 max-lg:basis-1/3 max-lg:justify-end">
        <PeopleFilled className="h-3 w-3 text-primary-900" />
        <p className="text-[13px] font-medium leading-tight text-primary-900">
          {position.seat_metacv_count}/{position.seat_count}
        </p>
      </div>
      <div className="flex basis-[15%] flex-col max-lg:basis-full">
        <span className="text-[13px] font-bold leading-tight text-chart-purple2">
          {position.progress_percent_total} %
        </span>
        <ExpBar className="h-2" percent={position.progress_percent_total} />
      </div>
      <div className="flex basis-[15%]">
        {position.state === "completed" ? (
          <Button
            onClick={() => openAnalysisReportModal(position.id)}
            className="flex-1 border-secondary_applicant-700 bg-secondary_applicant-100 px-6 py-2 text-secondary_applicant-700"
          >
            Görüntüle
          </Button>
        ) : (
          <Button
            onClick={() => navigate(`/cna/my-positions/${position.id}`)}
            className="flex-1 bg-primary-50 px-6 py-2 text-primary-600"
          >
            Devam Et
          </Button>
        )}
      </div>
    </div>
  );
};

const JobPosReports = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getJobPositionList = useStore((state) => state.getJobPositionList);
  const jobPositions = useStore((state) => state.jobPositions);

  // PAGINATION
  const [pageSize, setPageSize] = useState(6); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page

  useEffect(() => {
    const options = {
      page: currentPage,
      rows: pageSize,
      filter: { core_cv_completed: 1, tournament_completed: 1 },
    };
    getJobPositionList(options);
  }, [getJobPositionList, jobPositions.isLoaded, pageSize, currentPage]);
  if (!jobPositions.isLoaded) {
    return null;
  }
  return (
    <div className="flex h-full flex-col">
      <div className="mb-4 flex items-center justify-between rounded-md bg-[#4d5aa61f] px-4 py-2 max-lg:hidden">
        <div className="basis-[22%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Job Title</p>
        </div>
        <div className="basis-[10%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Kişi Sayısı</p>
        </div>
        <div className="basis-[15%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Progress</p>
        </div>
        <div className="basis-[15%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyon</p>
        </div>
      </div>
      <div className="-mx-2 -my-1 flex flex-col gap-3 overflow-y-auto px-3 py-1 lg:max-h-[calc(100%-64px)]">
        {/* {jobPositions.data.rows
          .map((position, index) => (
            <ReportRow key={index} position={position} />
          ))} */}
        {!jobPositions.isLoading ? (
          <>
            {jobPositions.data.rows.length ? (
              jobPositions.data.rows.map((position, index) => <ReportRow key={index} position={position} />)
            ) : (
              <div className="flex flex-1 flex-col items-center justify-center gap-1">
                <img src={emptyusers_aid} alt="empty positions" />
                <p className="text-sm font-semibold text-primary-800">Henüz İdeal Çalışan Profili tamamlamadınız.</p>
                <Button
                  onClick={() => {
                    navigate(`/cna/ideals`);
                  }}
                  className={"px-6 py-2"}
                  icon2={<RightArrowOutlined className={"h-4 w-4"} />}
                >
                  İdeal Çalışan Profilleri
                </Button>
              </div>
            )}
          </>
        ) : (
          <Loading />
        )}
      </div>
      <div className="mt-auto">
        {jobPositions.data.pagination_info.total_row_count > 0 && (
          <Pagination
            dataLength={jobPositions.data.pagination_info.total_row_count}
            setPageSize={setPageSize}
            currentPage={currentPage}
            totalCount={jobPositions.data.pagination_info.total_row_count}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
};

export default JobPosReports;
