import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/Humanas_loading.json";
import { twMerge } from "tailwind-merge";
import useIsMobile from "../../utils/useIsMobile";

const Loading = ({ className }) => {
  const isMobile = useIsMobile();
  const classes = `relative flex h-[90vh] items-center justify-center`;
  const mergedClasses = twMerge(classes, className);
  return (
    <div className={mergedClasses}>
      {/* <div className="h-12 w-12 animate-spin rounded-full border-y-4 border-solid border-blue-500 border-opacity-50 shadow-md"></div> */}
      <Lottie animationData={loadingAnimation} style={{ width: isMobile ? 300 : 400 }} />
    </div>
  );
};

export default Loading;
