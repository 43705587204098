import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import { DotOutlined, InfoOutlined, RightArrowOutlined } from "../OldDesign/Icons";
import { ORIENTATION_LEVEL_DESCRIPTIONS, ORIENTATION_STAT_COLORS } from "./constants";
import Tooltip from "./Tooltips/Tooltip";
import Button from "./Button/Button";

const OrientationSummary = ({ summary }) => {
  const metacvDimStats = summary;
  const colors = ORIENTATION_STAT_COLORS;
  const RADIAN = Math.PI / 180;
  const customLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        {percent > 0 && (
          <>
            <circle cx={x} cy={y} r={13} fill="white" opacity="0.5" />
            <text x={x} y={y} fill="#0D568A" fontWeight={600} textAnchor={"middle"} dominantBaseline="central">
              {`${(percent * 14).toFixed(0)}`}
            </text>
          </>
        )}
      </>
    );
  };

  const handleScrollToOrientation = (id) => {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex h-full w-full items-center gap-4 max-lg:flex-col">
      <PieChart width={150} height={150}>
        <Pie
          data={metacvDimStats}
          dataKey={"value"}
          nameKey={"title"}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={customLabel}
          outerRadius={75}
          innerRadius={45}
        >
          {metacvDimStats.map((entry, index) => (
            <Cell key={index} fill={colors[entry.id]} strokeWidth={0} />
          ))}
        </Pie>
      </PieChart>

      <div className="flex w-[350px] flex-col divide-y">
        {metacvDimStats.map((entry, index) => (
          <div key={index} className="flex flex-1 items-center gap-2 py-2 max-lg:py-0">
            <p className="flex items-center pr-1 text-[17px] font-semibold leading-tight text-primary-700 max-lg:text-[14px]">
              <span className={`pr-2 text-[10px]`} style={{ color: colors[entry.id] }}>
                {"\u2B24"}
              </span>
              {entry.title}
            </p>
            <Tooltip content={ORIENTATION_LEVEL_DESCRIPTIONS[entry.id]}>
              <InfoOutlined className={"h-4 w-4 text-neutral-600"} />
            </Tooltip>
            <Button
              className="ml-auto border-none bg-transparent p-0 text-primary-800"
              icon={<RightArrowOutlined className={"h-4 w-4"} />}
            >
              {entry.value}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrientationSummary;
