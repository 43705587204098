import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { metaCvActions } from "../../../api/endpoints";
import Loading from "../../OldDesign/Loading";
import CvHeader from "./CvHeader";
import ProfessionalOrientationSection from "./ProfessionalOrientationSection";
import CvFooter from "./CvFooter";
import ActionBar from "./ActionBar";
import CvCore from "./CvCore";
import CvAID from "./CvAID";
import CvInterviewQuestions from "./CvInterviewQuestions";
import CvComments from "./CvComments";
import { ConnectionsWorld } from "../Illustrations";
import userPP from "../../../user.png";
import { BirthdayFilled, CloseOutlined, LocationFilled, MailFilled, PhoneFilled } from "../../OldDesign/Icons";
import useIsMobile from "../../../utils/useIsMobile";
import MobileMetaCv from "../../MobileMetaCv/MobileMetaCv";

const MetaCvView = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showTopBar, setShowTopBar] = useState(false);
  const [metaCv, setMetaCv] = useState({});
  const IsMobile = useIsMobile();
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("header"); // Track active section

  const headerRef = useRef(null);
  const corecvRef = useRef(null);
  const orientationRef = useRef(null);
  const aidRef = useRef(null);
  const questionsRef = useRef(null);
  const commentRef = useRef(null);

  const metacvModalRef = useRef(null);

  const sections = [
    { title: "Meta CV Özet", id: "header", ref: headerRef },
    { title: "Core CV", id: "corecv", ref: corecvRef },
    { title: "Mesleki Yönelim", id: "orientation", ref: orientationRef },
    { title: "Humanas Kimliği", id: "aid", ref: aidRef },
    { title: "Mülakat Soruları", id: "questions", ref: questionsRef },
  ];

  const controlHeader = useCallback(() => {
    if (metacvModalRef.current.scrollTop > 340) {
      // if scroll down hide the navbar
      // console.log("gorun");
      setShowTopBar(true);
    } else {
      // if scroll up show the navbar
      // console.log("gizle");
      setShowTopBar(false);
    }
  }, []);

  const handleScrollToCommentSection = () => {
    commentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const handleScrollToSection = (id) => {
    const scrollRef = sections.find((item) => item.id === id).ref;
    scrollRef &&
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  };
  // console.log("loc", location);
  // console.log("metacv printable", metaCv);
  useEffect(() => {
    const payload = {
      type: "view",
      target: id,
      action: {},
    };
    metaCvActions(
      payload,
      (data) => {
        setMetaCv(data.data);
      },
      (err) => console.log(err),
      (bool) => setIsLoading(bool),
    );
  }, [id]);

  useEffect(() => {
    const scrollableCointainer = metacvModalRef.current;

    const handleScroll = () => {
      const sectionsRefs = [
        { ref: headerRef, id: "header" },
        { ref: corecvRef, id: "corecv" },
        { ref: orientationRef, id: "orientation" },
        { ref: aidRef, id: "aid" },
        { ref: questionsRef, id: "questions" },
      ];

      let currentSection = "";

      sectionsRefs.forEach(({ ref, id }) => {
        if (ref.current) {
          const sectionTop = ref.current.getBoundingClientRect().top;
          const sectionHeight = ref.current.getBoundingClientRect().height;
          // Check if the section is in the viewport (adjust tolerance as needed)
          if (
            sectionTop <= metacvModalRef.current.offsetHeight / 2 &&
            sectionTop + sectionHeight > metacvModalRef.current.offsetHeight / 2.5
          ) {
            currentSection = id;
          }
        }
      });
      // Set the active section if it has changed
      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
    };

    // We only attach the scroll listener once the data is loaded
    if (!isLoading && metaCv.id && scrollableCointainer) {
      // Add the scroll event listener
      scrollableCointainer.addEventListener("scroll", handleScroll);
      scrollableCointainer.addEventListener("scroll", controlHeader);
      // Remove the scroll event listener when component unmounts
      return () => {
        scrollableCointainer.removeEventListener("scroll", handleScroll);
        scrollableCointainer.removeEventListener("scroll", controlHeader);
      };
    }
  }, [isLoading, metaCv, activeSection, controlHeader]);

  if (isLoading) {
    return <Loading />;
  }
  if (!metaCv.id) {
    return (
      <div className="p-20">
        <ConnectionsWorld className="mx-auto w-[600px] pb-9" />
        <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">Meta Cv Bulunamadı</p>
      </div>
    );
  }

  return (
    <>
      {IsMobile ? (
        <MobileMetaCv setMetaCv={setMetaCv} metaCv={metaCv} id={id} onClose={() => navigate(-1)} />
      ) : (
        <>
          <div className="absolute -top-10">
            <div className="fixed">
              {sections.map((section, index) => (
                <button
                  key={index}
                  onClick={() =>
                    section.ref.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    })
                  }
                  className={`${index !== 0 && "ml-4"} ${
                    activeSection === section.id
                      ? " bg-neutral-100 font-semibold text-primary-600"
                      : "bg-primary-75 font-medium text-primary-300"
                  } trapezoid-container pb-5 pl-3 pt-3 leading-tight  transition-all`}
                  style={{ zIndex: activeSection === section.id ? 11 : 10 - index }}
                >
                  {section.title}
                </button>
              ))}
            </div>
          </div>
          <div className="absolute -top-12 right-[208px]">
            <div className="fixed flex items-center rounded-t-xl bg-neutral-100">
              <ActionBar
                handleScrollToCommentSection={handleScrollToCommentSection}
                user={metaCv}
                setMetaCv={setMetaCv}
              />
              <button className="mx-1.5 rounded-lg bg-card_candidate-100 p-2.5" onClick={() => navigate(-1)}>
                <CloseOutlined className="h-5 w-5 text-card_candidate-400" />
              </button>
            </div>
          </div>
          <div
            className={`${
              showTopBar ? "h-[70px] bg-neutral-100" : "h-0 bg-neutral-300 text-opacity-0"
            } fixed z-[1] flex w-[1240px] items-center justify-between overflow-hidden  px-6 shadow-lg transition-all duration-500`}
          >
            <div className="flex items-center gap-6">
              <div className="relative mb-2 inline-flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#069BF8] max-lg:h-[80px] max-lg:w-[80px]">
                <img
                  src={metaCv.profile_picture || userPP}
                  alt="profile"
                  className="h-[42px] w-[42px] rounded-full max-lg:h-[72px] max-lg:w-[72px]"
                />
                <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[8px] font-semibold text-neutral-100 max-lg:text-[11px]">
                  LVL {metaCv.metacv_level}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-lg font-bold leading-tight text-primary-900">{metaCv.name}</p>
                <p className="text-base font-medium leading-tight text-neutral-700">{metaCv.job.job_name}</p>
              </div>
            </div>
            <ul className="flex gap-4">
              <li className="text-sm leading-tight text-primary-900">
                <MailFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">{metaCv.email}</span>
              </li>
              <li className="text-sm leading-tight text-primary-900">
                <LocationFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">
                  {metaCv.location_state.state_name || "Not found"},{" "}
                  {metaCv.location_country.country_name || "Not found"}
                </span>
              </li>
              <li className="text-sm leading-tight text-primary-900">
                <PhoneFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">{metaCv.phone || "Not found"}</span>
              </li>
              <li className="text-sm leading-tight text-primary-900">
                <BirthdayFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">{metaCv.birth_date || "Not found"}</span>
              </li>
            </ul>
          </div>
          <div
            ref={metacvModalRef}
            className={`${
              showTopBar ? "mt-[70px] h-[calc(90vh-70px)]" : "mt-0 h-[calc(90vh)]"
            } relative mx-auto  grid  w-[1240px] grid-cols-1 gap-8 overflow-y-auto bg-neutral-100 px-9 pb-9 transition-all duration-500`}
          >
            <CvHeader
              handleScrollToSection={handleScrollToSection}
              ref={headerRef}
              user={metaCv}
              showTopBar={showTopBar}
            />
            <CvCore ref={corecvRef} user={metaCv} />
            <ProfessionalOrientationSection ref={orientationRef} user={metaCv} />
            <CvAID ref={aidRef} user={metaCv} />
            <CvInterviewQuestions ref={questionsRef} user={metaCv} />
            {metaCv.review.status > 0 && <CvComments user={metaCv} />}
            <CvFooter ref={commentRef} user={metaCv} />
          </div>
        </>
      )}
    </>
  );
};

export default MetaCvView;
