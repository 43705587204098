import React, { useEffect, useState } from "react";
import Button from "../../../components/NewDesign/Button/Button";
import GradientBorder from "../../../components/NewDesign/GradientBorder";
import { motion, AnimatePresence } from "framer-motion";
import { ASelect, Input, InputNumber } from "../../../components/NewDesign/Forms/Input";
import { Field } from "../../../components/NewDesign/Forms/Field";
import { TournamentCard } from "../../../components/NewDesign/Cards/TournamentCard";
import ProgressBar from "../../../components/NewDesign/StatusBar/ProgressBar";
import { CheckpointFilled, CheckpointNotFilled, MapPath } from "../../../components/NewDesign/Illustrations";
import {
  CheckOutlined,
  CopylinkOutlined,
  PeopleFilled,
  PlusCircularOutlined,
  RubbishOutlined,
} from "../../../components/OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_URL } from "../../../api/constants";
import { getCnaEntranceSummmary, postCnaEntranceSummmary } from "../../../api/endpoints";
import Loading from "../../../components/OldDesign/Loading";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import useIsMobile from "../../../utils/useIsMobile";
import { useStore } from "../../../store/useStore";

const LetsStart = () => (
  <>
    <h2 className="mb-6 text-center text-3xl font-bold leading-tight text-secondary_recruiter-800 max-lg:text-[22px]">
      Şirketinizi <br />
      daha{" "}
      <span className="bg-[linear-gradient(97deg,#5C38E4_105.36%,#E11BD4_-16.82%)] bg-clip-text text-transparent">
        yakından
      </span>{" "}
      tanıyalım!
    </h2>
    <p className="text-center text-sm leading-tight text-primary-900 max-lg:text-[13px] lg:mb-10">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mattis, libero vitae gravida facilisis, sem ipsum
      fringilla ante, ac consequat dui sem sed nisl. Phasellus porttitor dignissim erat et fermentum. Proin viverra,
      neque vehicula elementum scelerisque, ante risus ultrices erat, a pharetra
    </p>
  </>
);

const Sectors = ({ firstAnalysisData, setPayload }) => {
  const loadSectorOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/sector?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const handleSectorSelection = (sectorArray) => {
    setPayload((prev) => {
      return { ...prev, sectors: sectorArray };
    });
  };
  return (
    <div className="flex flex-col items-center gap-4 max-lg:gap-2">
      <p className="text-sm font-semibold leading-tight text-neutral-700">Soru 1/3</p>
      <p className="text-[14px] font-semibold leading-tight text-primary-900 max-lg:text-center">
        Firmanız hangi sektör(ler)de faaliyet gösteriyor?
      </p>
      <Field className="w-full">
        <ASelect
          loadOptions={loadSectorOptions}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={firstAnalysisData.sectors}
          onChange={(e, v) => handleSectorSelection(e)}
          defaultOptions
          isMulti
          name={"sectors"}
        />
      </Field>
    </div>
  );
};

const EmployeeCount = ({ firstAnalysisData, setPayload }) => {
  const loadEmployeeCountOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/employeecount?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const handleEmployeeCountSelection = (employeeCountOption) => {
    setPayload((prev) => {
      return { ...prev, employee_count: employeeCountOption };
    });
  };
  return (
    <div className="flex flex-col items-center gap-4">
      <p className="text-sm font-semibold leading-tight text-neutral-700">Soru 2/3</p>
      <p className="text-[14px] font-semibold leading-tight text-primary-900">
        Firmanızın güncel çalışan sayısını belirtiniz.
      </p>
      <Field className="w-full">
        <ASelect
          loadOptions={loadEmployeeCountOptions}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={firstAnalysisData.employee_count}
          defaultOptions
          onChange={(e) => handleEmployeeCountSelection(e)}
          min={0}
          name={"employee_count"}
        />
      </Field>
    </div>
  );
};

const Departments = ({ firstAnalysisData, setPayload }) => {
  const loadDepartmentOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/departmenttype?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const handleDepartmentSelection = (departmentsArray) => {
    setPayload((prev) => {
      return { ...prev, departments: departmentsArray };
    });
  };
  return (
    <div className="flex flex-col items-center gap-4">
      <p className="text-sm font-semibold leading-tight text-neutral-700">Soru 3/3</p>
      <p className="text-[14px] font-semibold leading-tight text-primary-900">
        Firmanızda yer alan departmanları belirtiniz.
      </p>
      <Field className="w-full">
        <ASelect
          loadOptions={loadDepartmentOptions}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={firstAnalysisData.departments}
          onChange={(e, v) => handleDepartmentSelection(e)}
          defaultOptions
          isMulti
          name={"departments"}
        />
      </Field>
    </div>
  );
};

const NeedsTournament = ({ setFirstAnalysisData, firstAnalysisData, setPayload, payload, forwardStep }) => {
  const questionsIndex = firstAnalysisData.basic_needs.current_index - 1;
  const isMobile = useIsMobile();
  const [submitting, setSubmitting] = useState(false);

  const toggleQuestionInOrder = (questionId) => {
    if (payload.basic_needs?.question_array[questionsIndex] === undefined) {
      const firstQuestionsArray = [...firstAnalysisData.basic_needs.question_array];
      firstQuestionsArray[questionsIndex] = {
        questions: firstAnalysisData.basic_needs.question_array[questionsIndex].questions,
        answers: [questionId],
      };
      // console.log("olusmamis yenisi", firstQuestionsArray);
      setPayload({ basic_needs: { question_array: firstQuestionsArray } });
    } else {
      const newQuestionsArray = [...payload.basic_needs.question_array];
      if (!payload.basic_needs?.question_array[questionsIndex].answers.includes(questionId)) {
        newQuestionsArray[questionsIndex].answers.length < 4 &&
          newQuestionsArray[questionsIndex].answers.push(questionId);
        // console.log("id icermiyor", newQuestionsArray);
      } else if (payload.basic_needs?.question_array[questionsIndex].answers.includes(questionId)) {
        let _index = newQuestionsArray[questionsIndex].answers.indexOf(questionId);
        newQuestionsArray[questionsIndex].answers.splice(_index, 1);
        // console.log("iceriyor cikar", newQuestionsArray);
      }
      setPayload({ basic_needs: { question_array: newQuestionsArray } });
    }
  };

  const handleTakeBack = () => {
    toggleQuestionInOrder(payload.basic_needs.question_array[questionsIndex].answers[3]);
    toggleQuestionInOrder(payload.basic_needs.question_array[questionsIndex].answers[2]);
  };

  const handleSubmitAnswers = () => {
    postCnaEntranceSummmary(
      payload,
      (res) => {
        setFirstAnalysisData(res.data);
        res.data.basic_needs.current_index > 10 && forwardStep();
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        setSubmitting(bool);
      },
    );
  };

  return (
    <>
      <p className="pb-4 text-sm font-semibold leading-tight text-neutral-700 max-lg:pb-1">Soru 3/3</p>
      <p className="pb-4 text-[14px] font-semibold leading-tight text-primary-900 max-lg:pb-2">
        Firmanızda en çok ihtiyaç duyulan iş düzeylerini sıralayız.
      </p>
      <AnimatePresence>
        <div className="flex flex-row max-lg:flex-col max-lg:gap-2 lg:gap-6">
          {firstAnalysisData.basic_needs.question_array[questionsIndex]?.questions.map((question, index) => {
            const variants = ["blue", "purple", "green", "orange"];
            return (
              <motion.div
                key={question.id}
                initial={{ rotate: 270, opacity: 0, y: 60 }}
                animate={{ rotate: 0, opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -60 }}
                transition={{ duration: 0.45, ease: "easeInOut" }}
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.1 }}
              >
                <TournamentCard
                  key={index}
                  onClick={() => toggleQuestionInOrder(question.id)}
                  variant={variants[index]}
                  selected={payload.basic_needs?.question_array[questionsIndex].answers.includes(question.id)}
                  order={payload.basic_needs?.question_array[questionsIndex].answers.indexOf(question.id)}
                  question={question.description}
                />
              </motion.div>
            );
          })}
          {isMobile &&
            payload.basic_needs &&
            payload.basic_needs.question_array[questionsIndex]?.answers.length === 4 && (
              <div className="absolute flex h-[488px] w-full items-center justify-center gap-2 backdrop-blur-sm">
                <Button onClick={handleTakeBack} className={`border-none bg-neutral-100 text-primary-600`}>
                  Geri Al
                </Button>
                <Button
                  loading={submitting}
                  onClick={handleSubmitAnswers}
                  className={`border-none bg-success-400 text-neutral-100`}
                >
                  Onayla
                </Button>
              </div>
            )}
        </div>
      </AnimatePresence>
    </>
  );
};

const CalculateFinish = ({ forwardStep }) => {
  const [percent, setPercent] = useState(0);
  const duration = 2500; // 2.5 seconds
  const interval = 50; // update every 50ms
  const increment = 100 / (duration / interval); // percent increment per interval

  useEffect(() => {
    const updatePercent = () => {
      setPercent((prevPercent) => {
        const newPercent = prevPercent + increment;
        return newPercent > 100 ? 100 : newPercent;
      });
    };

    const intervalId = setInterval(updatePercent, interval);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <>
      <AnimatePresence>
        <motion.div
          key={percent === 100 ? "completed" : "in-progress"}
          initial={{ opacity: 0, y: 60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -60 }}
          transition={{ duration: 0.45, ease: "easeInOut" }}
        >
          {percent === 100 ? (
            <>
              <p className="pb-4 text-[14px] font-semibold leading-tight text-primary-900">
                İhtiyaç yol haritanızı oluşturduk.
              </p>
              <button
                onClick={() => forwardStep()}
                className="h-[150px] w-[150px] rounded-full bg-[linear-gradient(40deg,rgba(54,9,159,1)_0%,rgba(121,134,232,1)_50%,rgba(130,183,234,1)_100%)] shadow-[0px_4px_16px_0px_rgba(83,108,240,0.50)]"
              >
                <div className="mx-auto flex h-[118px] w-[118px] items-center justify-center rounded-full bg-neutral-100">
                  <p className="text-[18px] font-semibold text-secondary_recruiter-900">Görüntüle</p>
                </div>
              </button>
            </>
          ) : (
            <>
              <div className="pb-6 max-lg:w-full lg:w-[400px]">
                <ProgressBar
                  className="bg-[linear-gradient(247deg,#82B7EA_2.82%,#7986E8_35.94%,#36099F_98.96%)]"
                  percent={percent}
                />
              </div>
              <p className="font-semibold leading-tight text-primary-900">İhtiyaç yol haritanız oluşturuluyor...</p>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const Roadmap = () => {
  return (
    <>
      <h4 className="pb-4 text-[22px] font-semibold leading-tight text-primary-900 max-lg:text-[18px]">
        İhtiyaç Yol Haritası
      </h4>
      <p className="pb-4 text-sm leading-tight text-neutral-700 max-lg:text-[13px]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mattis, libero vitae gravida facilisis, sem ipsum
        fringilla ante, ac consequat dui sem sed nisl. Phasellus porttitor dignissim erat et fermentum. Proin viverra,
        neque vehicula elementum.
      </p>
      <div className="relative mt-4 flex items-center justify-center max-lg:h-[70px] max-lg:w-full lg:h-[200px] lg:w-[880px]">
        <MapPath className="absolute max-lg:top-2.5 max-lg:w-[260px]" />

        <div className="absolute left-0 top-2 flex items-center justify-center">
          <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-100 max-lg:text-[7.5px]">
            1
          </span>
          <CheckpointFilled className={"max-lg:w-[40px]"} />
          <p className="absolute top-20 font-semibold leading-tight text-primary-900 max-lg:top-14 max-lg:text-[6px]">
            İş Pozisyonu Uygunluk Analizi
          </p>
        </div>

        <div className="absolute bottom-3 left-[21%] flex items-center justify-center max-lg:-bottom-16">
          <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700 max-lg:text-[7.5px]">
            2
          </span>
          <CheckpointNotFilled className={"max-lg:w-[40px]"} />
          <p className="absolute top-20 font-medium leading-tight text-neutral-700 max-lg:top-14 max-lg:text-[6px]">
            Lorem Ipsum
          </p>
        </div>

        <div className="absolute left-[43%] top-2 flex items-center justify-center">
          <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700 max-lg:text-[7.5px]">
            3
          </span>
          <CheckpointNotFilled className={"max-lg:w-[40px]"} />
          <p className="absolute top-20 font-medium leading-tight text-neutral-700 max-lg:top-14 max-lg:text-[6px]">
            Lorem Ipsum
          </p>
        </div>

        <div className="absolute bottom-3 left-[65%] flex items-center justify-center max-lg:-bottom-16">
          <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700 max-lg:text-[7.5px]">
            4
          </span>
          <CheckpointNotFilled className={"max-lg:w-[40px]"} />
          <p className="absolute top-20 font-medium leading-tight text-neutral-700 max-lg:top-14 max-lg:text-[6px]">
            Lorem Ipsum
          </p>
        </div>

        <div className="absolute left-[85%] top-2 flex items-center justify-center">
          <span className="absolute pb-2 text-[22px] font-medium leading-tight text-neutral-700 max-lg:text-[7.5px]">
            5
          </span>
          <CheckpointNotFilled className={"max-lg:w-[40px]"} />
          <p className="absolute top-20 font-medium leading-tight text-neutral-700 max-lg:top-14 max-lg:text-[6px]">
            Lorem Ipsum
          </p>
        </div>
      </div>
    </>
  );
};

const Positions = ({ forwardStep, setFirstAnalysisData }) => {
  const addJobPosition = useStore((state) => state.addJobPosition);
  const emptyJobPos = { name: "", seat_count: 0 };
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      job_positions: [emptyJobPos],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "job_positions",
  });

  const handleSubmitJobPositions = async (formData) => {
    await addJobPosition(formData);
    // await postCnaEntranceSummmary(
    //   formData,
    //   (res) => {
    //     forwardStep();
    //     setFirstAnalysisData(res.data);
    //   },
    //   (err) => console.log(err),
    //   (bool) => console.log(bool),
    // );
  };
  return (
    <div className="flex flex-col items-center gap-4 pt-6">
      <p className="text-sm leading-tight text-primary-800">
        Firmanızda bulunan pozisyonları belirleyin. Pozisyon ismi ve kişi sayısını girip 'Ekle' butonuna basmanız
        yeterlidir.
      </p>
      <ul className="flex w-full flex-col items-center gap-2">
        {fields.map((item, index) => {
          return (
            <li key={item.id} className="flex w-full items-center gap-2">
              <Field
                label={index === 0 && "Pozisyon ismi"}
                error={
                  errors.job_positions &&
                  (errors.job_positions[0]?.name || errors.job_positions[1]?.name || errors.job_positions[2]?.name)
                }
                className={"w-full"}
              >
                <Input
                  {...register(`job_positions.${index}.name`, { required: true, maxLength: 30 })}
                  placeholder="Pozisyon ismi"
                />
              </Field>
              <Field
                label={index === 0 && "Kişi Sayısı"}
                error={
                  errors.job_positions &&
                  (errors.job_positions[0]?.seat_count ||
                    errors.job_positions[1]?.seat_count ||
                    errors.job_positions[2]?.seat_count)
                }
                className={""}
                name={`job_positions.${index}.seat_count`}
              >
                <Controller
                  control={control}
                  name={`job_positions.${index}.seat_count`}
                  rules={{ required: true, min: 1 }}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return <InputNumber min={0} max={9999} onChange={onChange} />;
                  }}
                />
              </Field>
              {/* <Button
                onClick={() => remove(index)}
                className={`${index === 0 && "invisible "} border-none bg-transparent p-0`}
                icon={<RubbishOutlined className="h-6 w-6 text-primary-500" />}
              /> */}
            </li>
          );
        })}
        {fields.length < 3 && (
          <button onClick={() => append(emptyJobPos)}>
            <PlusCircularOutlined className={"hidden h-6 w-6 text-primary-600"} />
          </button>
        )}
      </ul>
      <Button
        loading={isSubmitting}
        className="self-end bg-neutral-100 px-6 py-2 text-primary-600"
        onClick={handleSubmit((formData, e) => handleSubmitJobPositions(formData))}
      >
        Ekle
      </Button>
    </div>
  );
};

const PositionInvitation = ({ firstAnalysisData }) => {
  const positions = firstAnalysisData.job_positions;

  const PositionRow = ({ position }) => {
    const [copied, setCopied] = useState(false);
    const copyLinkToClipboard = async (link) => {
      try {
        clearTimeout();
        navigator.clipboard.writeText(link).then(() => setCopied(true));
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      }
    };
    return (
      <div className="flex items-center justify-between rounded-xl bg-neutral-100 bg-opacity-50 px-[16px] py-[5px] shadow-[0px_4px_10px_0px_rgba(0,0,0,0.10)] backdrop-blur-[2px]">
        <p className="basis-1/3 text-left text-sm font-medium leading-tight text-primary-900">{position.name}</p>
        <div className="flex basis-1/3 items-center gap-0.5">
          <PeopleFilled className="h-4 w-4 text-primary-600" />
          <span className="text-sm font-medium leading-tight text-primary-900">{position.seat_count}</span>
        </div>
        <button
          disabled={copied}
          onClick={() => copyLinkToClipboard(position.invite_link)}
          className="rounded-xl bg-secondary_recruiter-200 p-3 text-secondary_recruiter-700"
        >
          <CheckOutlined
            className={`${copied ? "scale-100" : "scale-0"} absolute h-5 w-5 text-success-400 transition-all`}
          />
          <CopylinkOutlined className={`${copied ? "scale-0" : "scale-100"} transition-all`} />
        </button>
      </div>
    );
  };
  return (
    <>
      <p className="pb-4 text-sm font-semibold leading-tight text-neutral-700">Soru 3/3</p>
      <p className="pb-4 font-semibold leading-tight text-primary-900">Pozisyon Sayısı</p>
      <div className="flex items-center gap-4 pb-4 text-left">
        <p className="text-sm leading-tight text-primary-900">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent dictum, leo in interdum ultricies, elit
          massa laoreet massa, rutrum suscipit sapien nulla non mauris.
        </p>
        <Button
          className="bg-transparent text-primary-600"
          icon={<CopylinkOutlined className="" />}
          onClick={() => console.log("yok")}
        >
          Genel Davet Linki
        </Button>
      </div>
      <div className="flex max-h-[186px] flex-col gap-2 overflow-auto overflow-y-auto px-4 pb-2">
        {positions.map((position, index) => (
          <PositionRow position={position} key={index} />
        ))}
      </div>
    </>
  );
};

const CNAFirstAnalysis = () => {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstAnalysisData, setFirstAnalysisData] = useState({});
  const [payload, setPayload] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState(1);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const canGoBack = (currentStep > 0 && currentStep < 4) || currentStep > 7;

  const steps = [
    // { id: 1, component: LetsStart, canGoForward: true },
    {
      id: 1,
      component: Sectors,
      canGoForward:
        (firstAnalysisData.sectors && firstAnalysisData.sectors.length > 0) ||
        (payload.sectors && payload.sectors.length > 0),
    },
    {
      id: 2,
      component: EmployeeCount,
      canGoForward:
        (firstAnalysisData.employee_count && firstAnalysisData.employee_count.id >= 0) ||
        (payload.employee_count && payload.employee_count.id >= 0),
    },
    {
      id: 3,
      component: Departments,
      canGoForward:
        (firstAnalysisData.departments && firstAnalysisData.departments.length > 0) ||
        (payload.departments && payload.departments.length > 0),
    },
    // {
    //   id: 5,
    //   component: NeedsTournament,
    //   canGoForward:
    //     (payload.basic_needs &&
    //       payload.basic_needs.question_array[firstAnalysisData.basic_needs.current_index - 1]?.answers.length === 4) ||
    //     (firstAnalysisData.basic_needs && firstAnalysisData.basic_needs.current_index > 10),
    // },
    // { id: 6, component: CalculateFinish, canGoForward: true },
    // { id: 7, component: Roadmap, canGoForward: true },
    {
      id: 4,
      component: Positions,
      canGoForward:
        (payload.job_positions && payload.job_positions.length > 0) ||
        (firstAnalysisData.job_positions && firstAnalysisData.job_positions.length > 0),
    },
    // { id: 9, component: PositionInvitation, canGoForward: true },
  ];

  const forwardStep = () => {
    setDirection(1);
    setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
  };

  const nextStep = () => {
    postCnaEntranceSummmary(
      payload,
      (res) => {
        setFirstAnalysisData(res.data);
        (currentStep !== 4 || res.data.basic_needs.current_index > 10) && forwardStep();
      },
      (err) => console.log(err),
      (bool) => setSubmitting(bool),
    );
  };

  const backStep = () => {
    setDirection(-1);
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const CurrentComponent = steps[currentStep].component;

  const variants = {
    enter: (direction) => ({
      x: direction === 1 ? 500 : -500,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction === 1 ? -500 : 500,
      opacity: 0,
    }),
  };

  useEffect(() => {
    //call cna entrance summary
    getCnaEntranceSummmary(
      (res) => {
        setFirstAnalysisData(res.data);

        // if (res.data.state === "employee_count") {
        //   setCurrentStep(2);
        // } else if (res.data.state === "departments") {
        //   setCurrentStep(3);
        // } else if (res.data.state === "basic_needs") {
        //   setCurrentStep(4);
        // } else if (res.data.state === "job_positions") {
        //   setCurrentStep(6);
        // } else if (res.data.state === "completed") {
        //   setCurrentStep(3);
        // }
        res.data.state === "completed" && setCurrentStep(3);
      },
      (err) => console.log(err),
      (bool) => setLoading(bool),
    );
  }, [navigate]);

  // console.log("etnrance data", firstAnalysisData);
  // if (loading) {
  //   return ;
  // }
  return (
    <div className="flex h-full flex-col  overflow-y-visible">
      {loading ? (
        <Loading className={"h-full w-full max-lg:h-[324px]"} />
      ) : (
        <>
          <h3 className="pb-4 text-[20px] font-bold leading-tight text-primary-900 max-lg:text-lg">
            {currentStep === steps.length - 1 ? "Pozisyon Ekle" : "Şirket Bilgileri"}
          </h3>
          <div className={`relative flex items-center max-lg:h-[240px] max-lg:items-start lg:h-[calc(100%-95px)] `}>
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={currentStep}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 0.5 }}
                style={{ position: "absolute", paddingBottom: "40px", zIndex: 50, width: "100%" }}
              >
                <CurrentComponent
                  setFirstAnalysisData={setFirstAnalysisData}
                  firstAnalysisData={firstAnalysisData}
                  setPayload={setPayload}
                  payload={payload}
                  forwardStep={forwardStep}
                />
              </motion.div>
            </AnimatePresence>
          </div>
          {currentStep !== steps.length - 1 && (
            <div className="flex items-center justify-center gap-4">
              <Button
                disabled={currentStep === 0}
                className={"border-transparent bg-transparent text-primary-600"}
                onClick={backStep}
              >
                Geri
              </Button>
              <Button loading={submitting} disabled={!steps[currentStep].canGoForward} onClick={nextStep}>
                Ileri
              </Button>
            </div>
          )}
        </>
      )}
    </div>
    // <section className="h-screen bg-cna-gradientblur bg-cover bg-center pt-[64px] max-lg:pt-[53px]">
    //   <GradientBorder
    //     className={`${
    //       currentStep === 4 ? "max-lg:h-[560px]" : "max-lg:h-[450px]"
    //     } absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 overflow-clip max-lg:w-[342px] lg:h-[450px] lg:w-[950px]`}
    //   >
    //     <div className="relative h-full rounded-xl bg-neutral-100 bg-opacity-20 p-8 text-center backdrop-blur-md max-lg:p-4">
    //       <div
    //         className={`${
    //           currentStep === 4 ? "max-lg:h-[490px] max-lg:pt-0" : "max-lg:pt-6"
    //         } relative flex h-[calc(100%-54px)] items-center justify-center max-lg:items-start `}
    //       >
    //         <AnimatePresence initial={false} custom={direction}>
    //           <motion.div
    //             key={currentStep}
    //             custom={direction}
    //             variants={variants}
    //             initial="enter"
    //             animate="center"
    //             exit="exit"
    //             transition={{ duration: 0.5 }}
    //             style={{ position: "absolute", paddingBottom: "40px", zIndex: 50 }}
    //           >
    //             <CurrentComponent
    //               setFirstAnalysisData={setFirstAnalysisData}
    //               firstAnalysisData={firstAnalysisData}
    //               setPayload={setPayload}
    //               payload={payload}
    //               forwardStep={forwardStep}
    //             />
    //           </motion.div>
    //         </AnimatePresence>
    //       </div>
    //       <div className="flex items-center justify-center gap-4">
    //         {canGoBack && (
    //           <Button className={"border-transparent bg-transparent text-primary-600"} onClick={backStep}>
    //             Geri
    //           </Button>
    //         )}
    //         {currentStep === 0 && (
    //           <Button className={"mx-auto"} onClick={nextStep}>
    //             Haydi Başlayalım
    //           </Button>
    //         )}
    //         {currentStep !== 0 &&
    //           currentStep !== 5 &&
    //           currentStep !== 7 &&
    //           currentStep < steps.length - 1 &&
    //           (!isMobile || currentStep !== 4) && (
    //             <Button loading={submitting} disabled={!steps[currentStep].canGoForward} onClick={nextStep}>
    //               Ileri
    //             </Button>
    //           )}
    //         {currentStep === steps.length - 1 && <Button onClick={() => navigate("/cna/overview")}>Bitir</Button>}
    //       </div>
    //     </div>
    //   </GradientBorder>
    // </section>
  );
};

export default CNAFirstAnalysis;
