import React from "react";
import SectionTitle from "../SectionTitle";
import userPP from "../../../user.png";

const CvComments = ({ user }) => {
  return (
    <>
      <SectionTitle title={"Yorumlar"} className={"items-start gap-1 text-[20px] max-lg:hidden"} />
      <div className="flex gap-2 max-lg:p-2">
        <img src={userPP} alt="" className="h-[50px] w-[50px] rounded-full" />
        <div className="flex flex-1 flex-col gap-1.5">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium leading-tight text-neutral-700">{user.review.reviewer_name}</p>
            <span className="text-sm font-medium leading-tight text-neutral-700">{user.review.review_date}</span>
          </div>
          <div className="rounded-r-3xl rounded-bl-3xl border border-neutral-300 bg-neutral-200 p-4">
            <p className="text-sm leading-tight text-primary-900">{user.review.review}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CvComments;
