import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { WORK_STATE } from "../../constants";
import { LeftCircularOutlined } from "../../../OldDesign/Icons";

const WorkingSituationCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);
  let defaultValue = "";
  if (coreCV.work_state) {
    defaultValue = WORK_STATE.find((s) => s.value === coreCV.work_state);
    // console.log(defaultValue);
  }
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      work_state: defaultValue,
    },
    mode: "onSubmit",
  });
  const sendFormData = async (formData) => {
    const payload = { work_state: formData.work_state.value };

    // console.log(formData, payload);
    await updateApplicationForm(payload, props.handleForwardForm);
  };
  return (
    <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
      <fieldset className="flex flex-col gap-4">
        <h2 className="mb-4 border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
          {content["calisma tercihi baslik"]}
        </h2>
        <Field label={content["calisma durumunuz baslik"]} error={errors?.work_state}>
          <Controller
            control={control}
            name="work_state"
            rules={{ required: "Required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                options={WORK_STATE}
                placeholder="Seçiniz"
                isClearable
                isSearchable
                getOptionLabel={(opt) => opt.label}
                getOptionValue={(opt) => opt.value}
                styles={{
                  control: (styles, state) => ({
                    ...styles,
                    borderRadius: "8px",
                    borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                    borderWidth: "1px",
                  }),
                  valueContainer: (styles, state) => ({
                    ...styles,
                    height: "50px",
                    paddingLeft: "16px",
                  }),
                }}
              />
            )}
          />
        </Field>
      </fieldset>
      <div className="flex justify-center gap-20">
        <button disabled={isSubmitting} type="button" id="backwardArrow" onClick={() => props.handleBackwardForm()}>
          <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
        </button>
        <button disabled={isSubmitting} type="submit" id="forwardArrow">
          <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
        </button>
      </div>
    </Form>
  );
};

export default WorkingSituationCard;
